let appMode = 'production';
if (window.location.origin === 'http://localhost:4000' || window.location.origin === 'http://localhost:3000') {
    appMode = 'stage';
}
let newlive = 'https://theperfumesmell.com/admin/api/'
let stage = 'https://theperfumesmell.com/admin/api/'
let image = 'https://theperfumesmell.com/admin/api/';
if (appMode === 'production') {
    newlive = 'http://tps.bloomingdalesnursery.co/admin/api/'
    image = 'https://theperfumesmell.com/admin/api/';
} else {
    stage = 'http://theperfumesmell.local:81/api/'
    image = 'http://theperfumesmell.local:81/api/'
}
let URL = (appMode === 'production') ? newlive : stage;
let pathObj = {
    baseURL: URL,
    //Image URL
    imageURL: image,
    googleApiKey: 'AIzaSyCsPhnTTzy7vsbbJuThzzfSQOQRf1D4mWY',
    dashboard: "dashboard",
    brands: "brandsList",
    product: "product",
    productDetails: "product-details",
    CommonApi: "common-api",
    setting: "setting",
    guestUser: "guest-user",
    forgotPassword: "forgot-password",
    resetPassword: "reset-password",
    login: "login",
    register: "register",
    otpVerify: "otp-verify",
    sendOtp: "send-otp",
    ResendOtp: "resend-otp",
    logout: "logout",
    profile: "get-profile",
    updateProfile: "update-profile",
    staticContent: "static-content",
    contactUs: "contact-us",
    subscribeNewsletter: "subscribe-newsletter",
    AddRemoveWishlist: 'add-remove-wishlist',
    ProductAvailability: 'product-availability',
    AddtoCart: 'add-to-cart',
    getCart: 'get-cart',
    cartDestroy: 'cart-destroy',
    getAllAddress: 'get-all-address',
    getWishList: 'get-wishlist',
    commonApi: "common-api",
    productRatingList: 'product-rating-list',
    getFilterData: 'get-filter-data',
    getCountry: "country",
    addEditAddress: "add-edit-address",
    getAllAddress: 'get-all-address',
    deleteAddress: "delete-address",
    getAddress: 'get-address',
    checkout: 'checkOutWeb',
    notificationList: 'notification-list',
    readNotification: 'read-notification',
    removeNotification: 'remove-notification',
    readAllNotification: 'read-all-notification',
    removeAllNotification: 'remove-all-notification',
    getOrder: "order-list",
    cancelOrder: "cancel-order",
    reorder: "reorder",
    orderDetails: "order-detail",
    rateProduct: "rate-product",
    walletList: "wallet-list",
    addMoney: "add-money",
    giftCard: "gift-card",
    submitGiftCard: "submit-gift-card",
    setDefaultAddress: "set-default",
    getOffer: "offers",
    notifyMe: "notify-me",
    comboDetail: "combo-detail",
    addToCombo: "add-to-combo",
    applyDiscount: "apply-discount",
    removeDiscount: "remove-discount",
    getStates: 'states',
    getNewsTicker: "news-ticker",
    newsTickerDetails: "news-ticker-details",
    setPreference: "set-preference",
    paymentByLink: 'paymentByLink1',
    tabbyByLink: 'tabby_checkout/payment',
    checkPaymentTabby: 'tabby_checkout/check-payment',
    WalletByLink: 'WalletByLink',
    pickupStore: 'pickup-store',
    advanceSearch: 'advance_search',
    saleProduct: 'sale-product',
    sociallogin: "sociallogin",
    changeMobileNumber: "change-mobile-number",
    verifyMobileNumber: "verify-mobile-number",
    calculateShippingCharge: "calculateShippingCharge",
    trackingOrder: "tracking-order"
}
export default pathObj;
