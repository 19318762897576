import React, { useState, useEffect } from 'react';
import { Row, Col, Button, FormGroup } from 'react-bootstrap';
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import validator from 'validator';
import toast, { Toaster } from 'react-hot-toast';

import apiPath from "../../constants/apiPath";
import Http from "../../services/Http";

import LocalizedStrings from 'react-localization';
import localization from '../../localization';
let strings = new LocalizedStrings(localization);


const NewsLetter = () => {



    const [contact, setContact] = useState([]);

    const [currentLang, setCurrentLang] = useState(localStorage.getItem('current_lang') ? localStorage.getItem('current_lang') : 'en');

    const [comman, setComman] = useState({
        setFocusOnError: true,
        clearInputOnReset: false
    });

    const formRef = React.createRef();

    const handleChange = (e) => {
        setContact({ ...contact, [e.target.name]: e.target.value })
    }

    const handleSignupSubmit = async (e) => {
        e.preventDefault();

        let path = apiPath.subscribeNewsletter;
        var data = new FormData();
        data.append('email', contact.email);
        let options = {
            method: 'POST',
            data: data
        }
        let result = await Http(path, options);
        if (result.status === 200) {
            if (result.data.status) {
                setContact(null);
                toast.success(result.data.message, {
                    iconTheme: {
                        primary: '#b30a59',
                        secondary: '#fff',
                    }
                });
            } else {
                toast.error(result.data.message);
            }
        } else {
            toast.error(result.statusText);
        }


    }

    strings.setLanguage(currentLang);

    return (
        <ValidationForm onSubmit={handleSignupSubmit} ref={formRef} immediate={comman.immediate} setFocusOnError={comman.setFocusOnError} defaultErrorMessage={{ required: "Please enter something." }} >
            <div className="newsletter-wrap">
                <div className="form-group">
                    <div className="input-group">
                        <label>{strings.subscribeOutNewsLetter}</label>
                        <div className="form-wrap">
                            <FormGroup>
                                <TextInput name="email" id="email" type="text" required
                                   // validator={(value) => validator.isEmail(value) && (!validator.contains(value, '+'))}
                                    errorMessage={{ required: strings.enterTheEmail, pattern: strings.invalidEmailAddress }}
                                    pattern='^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+.[a-zA-Z]+$'                                    
                                    value={contact ? contact.email : ''}
                                    onChange={handleChange}
                                    placeholder={strings.enterTheEmail}
                                />
                            </FormGroup>
                            <div className="subs-btn">
                                <Button type="submit" className="btn btn_primary">{strings.submit}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ValidationForm>
    )
}

export default NewsLetter;