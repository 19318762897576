import OwlCarousel from 'react-owl-carousel3';

import { Link } from "react-router-dom";

const Banner = (Props) => {


    return (
        <>

            <OwlCarousel className="owl-carousel  owl-theme" {...Props.options}  >
                {Props.data.map((item, index) =>
                    item.banner_type == 'video' ?
                        <video width="100%" height={487} controls>
                            <source src={item.file} type="video/mp4" />
                            <source src={item.file} type="video/ogg" />
                            <source src={item.file} type="video/webm" />
                        </video> : <div className="item">
                            <div className="banner-img">
                                {Props.type == 'Banner' ? <Link to={'/product/' + (item.type != "product" ? item.type : "product-detail") + "/" + item.banner_for_id}><picture>
                                    <source media="(min-width:575.98px)" srcSet={item.file ? item.file : item.image} />
                                    <img src={item.file ? item.file : item.image} alt="" />
                                </picture></Link> : Props.type == 'sale' ? <Link to={'/product/' + (item.type != "product" ? item.type : "product-detail") + "/" + item.banner_for_id}><picture>
                                    <source media="(min-width:575.98px)" srcSet={item.file ? item.file : item.image} />
                                    <img src={item.file ? item.file : item.image} alt="" />
                                </picture></Link> : Props.type == 'top_brand' ? <Link to={'product/brand/' + item.id}>
                                    <picture>
                                        <source media="(min-width:575.98px)" srcSet={item.file ? item.file : item.image} />
                                        <img src={item.file ? item.file : item.image} alt="" />
                                    </picture>
                                </Link> : Props.type == 'brand' ? <Link to={'product/' + Props.type + '/' + item.id}>
                                    <picture>
                                        <source media="(min-width:575.98px)" srcSet={item.file ? item.file : item.logo} />
                                        <img src={item.file ? item.file : item.logo} alt="" />
                                    </picture>
                                </Link> :
                                    <Link to={'product/' + Props.type + '/' + item.banner_for_id}>
                                        <picture>
                                            <source media="(min-width:575.98px)" srcSet={item.file ? item.file : item.image} />
                                            <img src={item.file ? item.file : item.image} alt="" />
                                        </picture>
                                    </Link>
                                }

                            </div>
                        </div>
                )}
            </OwlCarousel>

        </>

    )
}
export default Banner;