import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import { Modal, Form, Button } from "react-bootstrap";

import Header from "../layout/Header";
import Footer from "../layout/Footer";
import LeftSitebar from "../layout/LeftSitebar";
import ImgUser from "../assets/img/user-icon.png";

import { userLogin } from "../actions/index";
import { connect } from "react-redux";

import apiPath from "../constants/apiPath";
import Http from "../services/Http";
import toast, { Toaster } from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import LocalizedStrings from "react-localization";
import localization from "../localization";
let strings = new LocalizedStrings(localization);

const mapStateToProps = (state) => {
    return { user: state.user };
};

const mapDispatchToProps = (dispatch) => {
    return {
        userLogin: (user) => dispatch(userLogin(user)),
    };
};


const Profile = (Props) => {
    const [profile, setProfile] = useState([]);
    const [address, setAddress] = useState([]);

    const [user, setUser] = useState(
        Object.keys(Props.user).length > 0 ? Props.user : null
    );

    const [currentLang, setCurrentLang] = useState(
        localStorage.getItem("current_lang")
            ? localStorage.getItem("current_lang")
            : "en"
    );
    strings.setLanguage(currentLang);


    useEffect(() => {
        getProfile();
        getAddress();
    }, [Props]);

    const setDefault = async (e, id) => {
        const statusMsg = `Are you sure you want to set default address`;
        if (window.confirm(statusMsg)) {
            // alert(id)
            let path = apiPath.setDefaultAddress + "/" + id;
            var data = new FormData();
            let options = {
                method: "GET",
            };
            let result = await Http(path, options);
            if (result.status === 200) {
                if (result.data.status) {
                    //  setAddress({ data: });
                    window.location.reload();
                } else {
                    toast.error(result.data.message);
                }
            } else {
                toast.error(result.statusText);
            }
        }

    }
    const deleteAddress = async (id) => {
        const statusMsg = `Are you sure you want to delete address`;
        if (window.confirm(statusMsg)) {
            // alert(id)
            let path = apiPath.deleteAddress + "/" + id;
            var data = new FormData();
            let options = {
                method: "GET",
            };

            let result = await Http(path, options);
            if (result.status === 200) {
                if (result.data.status) {
                    //  setAddress({ data: });
                    window.location.reload();
                } else {
                    toast.error(result.data.message);
                }
            } else {
                toast.error(result.statusText);
            }
        }
        // alert("delete")
    };

    const getAddress = async () => {
        let path = apiPath.getAllAddress;
        var data = new FormData();
        let options = {
            method: "post",
            data: data,
        };

        let result = await Http(path, options);
        if (result.status === 200) {
            if (result.data.status) {
                setAddress({ data: result.data.data });

            } else {
                toast.error(result.data.message);
            }
        } else {
            toast.error(result.statusText);
        }
    };
    const getProfile = async () => {
        /*
    let path = apiPath.profile;
    var data = new FormData();
    let options = {
      method: 'GET',
      data:data
    }
 
    let result = await Http(path, options);
    if (result.status === 200) {
      if (result.data.status) {
        setProfile({data:result.data.data});

        
      } else {
        toast.error(result.data.message);
      }
    } else {
      toast.error(result.statusText);
    }*/
    };

    return (
        <>
            {user ? (
                user.details.temp_user == 1 ? (
                    <Navigate to="/" replace={true} />
                ) : (
                    ""
                )
            ) : (
                <Navigate to="/" replace={true} />
            )}

            <Header />
            <main>
                <section className="space-cls my-account-page">
                    <div className="container">
                        <div className="my-account-inner">
                            <LeftSitebar />

                            <div className="myaccount_r myaccount_display">
                                {user ? (
                                    <div className="myaccount_bg">
                                        <div className="myaccount_dtl">
                                            <div className="profile_bg">
                                                <div className="profile_img">
                                                    <img
                                                        src={user.details.image}
                                                        alt="User"
                                                    />
                                                </div>
                                            </div>

                                            <div className="user-profile-dtl">
                                                <h3>{user.details.name}</h3>
                                                {user.details.email ? (
                                                    <div className="user-mail">
                                                        <span>
                                                            {strings.emailAddress}
                                                        </span>
                                                        <Link
                                                            to={
                                                                "mailto:" +
                                                                user.details
                                                                    .email
                                                            }
                                                        >
                                                            {user.details.email}
                                                        </Link>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}

                                                <div className="user-contact-dtl">
                                                    {user.details.mobile ? (
                                                        <div className="mob-n">
                                                            <span>
                                                                {strings.mobile_number}
                                                            </span>
                                                            <Link
                                                                to={
                                                                    "tel:+" +
                                                                    user.details
                                                                        .country_code +
                                                                    " " +
                                                                    user.details
                                                                        .mobile
                                                                }
                                                            >
                                                                {"+" +
                                                                    user.details
                                                                        .country_code +
                                                                    " " +
                                                                    user.details
                                                                        .mobile}
                                                            </Link>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {user.details
                                                        .whatsapp_number ? (
                                                        <div className="whatsapp-n">
                                                            <span>
                                                                {strings.WhatsappNumber}
                                                            </span>
                                                            <Link
                                                                to={
                                                                    "tel:+" +
                                                                    user.details
                                                                        .whatsapp_number
                                                                }
                                                            >
                                                                {
                                                                    "+" + user.details
                                                                        .whatsapp_number.slice(0, 3) + " " +
                                                                    user.details
                                                                        .whatsapp_number.slice(3, user.details
                                                                            .whatsapp_number.toString().length)

                                                                }
                                                            </Link>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <div className="edit-profile">
                                                    <Link
                                                        to="/edit-profile"
                                                        className="btn btn_primary"
                                                    >
                                                        {strings.edit_profile}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <Skeleton height={60} count={2} />
                                )}

                                {
                                    <div className="added-address">
                                        <div className="address_profile_title">
                                            <h3 className="heading-type2">
                                                {strings.my_address}

                                            </h3>
                                            {/* <div className="addAddress_cls">
                                                <Link
                                                    to="/add-address"
                                                    className="btn btn_primary"
                                                >
                                                    {strings.add_address}

                                                </Link>
                                            </div> */}
                                        </div>
                                        <div className="address_select">
                                            {address.data && address.data.length > 0 ?

                                                < div className="address_inner_select">
                                                    <label className="custom_radio_b">
                                                        <input
                                                            type="radio"
                                                            checked={
                                                                address.data[0].is_default
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="radio"
                                                            onChange={(e) => setDefault(e, address.data[0].id)}
                                                        />
                                                        <span className="checkmark"></span>
                                                        <span className="address_tit">
                                                            {
                                                                strings[address.data[0].address_type]
                                                            }
                                                        </span>
                                                        <span className="address_tag">
                                                            {
                                                                address.data[0].flat_floor ? address.data[0].flat_floor + "," : ''
                                                            }
                                                            {
                                                                address.data[0].city_id ? address.data[0].city_id + "," : ''
                                                            }
                                                            {
                                                                address.data[0].country ? address.data[0].country.name : ' '
                                                            }


                                                            {/* {
                                                                            item.pincode
                                                                        } */}
                                                        </span>
                                                    </label>
                                                    {/* <div className="edit_profile_action">
                                                                    <Link
                                                                        to={
                                                                            "/edit-address/" +
                                                                            btoa(
                                                                                item.id
                                                                            )
                                                                        }
                                                                        className="btn btn_primary"
                                                                    >
                                                                        {strings.edit}

                                                                    </Link>
                                                                    {item.is_default == 0 ?
                                                                        <Button
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.stopPropagation();
                                                                                deleteAddress(
                                                                                    item.id
                                                                                );
                                                                            }}
                                                                            className="btn btn_primary"
                                                                        >
                                                                            {strings.delete}
                                                                        </Button>

                                                                        : null}
                                                                </div> */}
                                                    {/* <a href="#">Edit</a> */}
                                                    {/* (e) => {e.stopPropagation(); test() } */}
                                                </div>

                                                : null}
                                            {/* <div className="address_inner_select">
                      <label className="custom_radio_b">
                        <input type="radio" name="radio" />
                        <span className="checkmark"></span>
                        <span className="address_tit">Office</span>
                        <span className="address_tag">123, lorem ipsum, dummy text</span>
                      </label>
                      <a href="#">Edit</a>
                    </div> */}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </main >
            <Footer />
        </>
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
