import OwlCarousel from 'react-owl-carousel3';
import { Link } from "react-router-dom";
import ImgmSale4 from '../../assets/img/trending.png';
const TopTranding = (Props) =>{

    return (
        <>           	   
				<OwlCarousel className="owl-carousel  owl-theme" {...Props.options}  >
                    {Props.data.map((item, index) =>
                     item.banner_type=='video' ?                     
                         <video width="100%" height={487} controls>
                                <source src={item.file} type="video/mp4"/>
                                <source src={item.file} type="video/ogg"/>
                                <source src={item.file} type="video/webm"/>
                                </video> :<div className="item">
	        					<div className="prd-box">
                                    <div className="prd-wrap">
                                        <div className="trending-wrap">
                                        <Link to={'product-detail/'+item.id} target="_blank">
                                                <img src={ImgmSale4} alt="Trending"/>
                                            </Link>      
                                        </div>
                                        <div className="prd-img">
                                            <Link to={'product-detail/'+item.id} target="_blank"> 
                                            <img src={item.image} alt="Product"/>
                                            </Link>
                                        </div>
                                        <div className="prd-title"> 
                                            <h4>{item.brand_name}</h4>
                                        </div>
                                    </div>
	        					</div>
	        				</div>
                    )}
					</OwlCarousel> 
	      		
       </>

    )
}
export default TopTranding;