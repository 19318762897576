import React, { useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel3';
import Accordion from 'react-bootstrap/Accordion';

import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'

import { Link, useParams } from 'react-router-dom';
import { useStateWithCallbackLazy } from 'use-state-with-callback';


import Header from '../layout/Header';
import Footer from '../layout/Footer';
import ProductList from './Component/ProductList';
import apiPath from "../constants/apiPath";
import Http from "../services/Http";
import toast, { Toaster } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import newsImg from '../assets/img/news-img.png';
import { Col, Container, Row } from 'react-bootstrap';



const NewsTicker = () => {
   const [newsTicker, setNewsTicker] = useState([]);
   const [comman, setComman] = useStateWithCallbackLazy({
      page: 1,
      last_page: 0,
   });
   const getNewsTicker = async () => {
      let path = apiPath.getNewsTicker;
      var data = new FormData();
      let options = {
         method: 'GET',
         data: data
      }
      let result = await Http(path, options);
      if (result.status === 200) {
         if (result.data) {
            let resultJson = result.data.data
            setNewsTicker({ data: resultJson })
         } else {
            toast.error(result.data.message);
         }
      } else {
         toast.error(result.statusText);
      }
   }
   useEffect(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      getNewsTicker();
   }, []);
   return (
      <>
         <Header />
         <section className='news_ticker_sec space-cls'>
            <div className='news_inner'>
               <Container>
                  <h2 class="heading-type2">News Ticker</h2>
                  <Row>
                     {newsTicker ? newsTicker.data ? newsTicker.data.map((data) =>

                        <Col sm="6" md="4" lg="3" className='col_5'>
                           <Link to={"/news-ticker-detail/" + data.id}>
                              <div className='news_box'>
                                 <div className='news_img'>
                                    <img src={data.image} />
                                 </div>
                                 <div className='news_content'>
                                    {/* <h3>Lorem Ipsum is simply dummy text of the printing </h3> */}
                                    <p dangerouslySetInnerHTML={{ __html: data ? data.description : '' }}></p>
                                 </div>
                              </div>
                           </Link>
                        </Col>

                     ) : "No data Found" : "No data Found"}

                  </Row>
               </Container>
            </div>
         </section>
         <Footer />
      </>
   )
}

export default NewsTicker;