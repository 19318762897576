import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { userLogin } from "../actions/index";
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import LeftSitebar from '../layout/LeftSitebar';

import ImgmA1 from '../assets/img/a1.png';
import ImgmStar from '../assets/img/star.png';
import ImgmCross from '../assets/img/cross.png';
import noFav from '../assets/img/no-favorites.png';
import apiPath from "../constants/apiPath";
import Http from "../services/Http";

import toast, { Toaster } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Link, Navigate } from 'react-router-dom';
import Currency from './Component/Currency';

import LocalizedStrings from "react-localization";
import localization from "../localization";
let strings = new LocalizedStrings(localization);

const mapStateToProps = state => {
   return { user: state.user };
};

const mapDispatchToProps = dispatch => {
   return {
      userLogin: user => dispatch(userLogin(user))
   };
}



const Wishlist = (Props) => {
   const [user, setUser] = useState(Object.keys(Props.user).length > 0 ? Props.user : null)
   const [comman, setComman] = useState({
      loginModel: false,
      qty: 1
   });
   const [wishList, setWishList] = useState([]);

   const [currentLang, setCurrentLang] = useState(
      localStorage.getItem("current_lang")
         ? localStorage.getItem("current_lang")
         : "en"
   );
   strings.setLanguage(currentLang);


   const showSignBox = () => {
      setComman({ ...comman, loginModel: true });
   }
   const getList = async () => {
      let path = apiPath.getWishList;
      var data = new FormData();
      let options = {
         method: 'get',
         data: data
      }

      let result = await Http(path, options);
      if (result.status == 200) {
         if (result.data.status) {

            setWishList({ data: result.data.data });
            // toast.success(result.data.message);
         }
         else {
            setWishList([]);
            toast.error(result.data.message);
         }
      }
   }
   const deleteItem = async (id, type) => {

      let path = apiPath.AddRemoveWishlist;
      var data = new FormData()
      data.append('product_id', id);
      data.append('type', type);
      let options = {
         method: 'POST',
         data: data
      }

      let result = await Http(path, options);
      if (result.status === 200) {
         if (result.data.status) {
            toast.success(result.data.message);
            getList()
         } else {
            toast.error(result.data.message);
         }
      } else {
         toast.error(result.statusText);
      }


   }
   const addtoCart = async (type, id) => {
      if (comman.qty > 0) {
         let path = apiPath.AddtoCart;
         var data = new FormData();
         data.append('product_id', id);
         data.append('qty', comman.qty);
         let options = {
            method: 'POST',
            data: data
         }
         let result = await Http(path, options);
         if (result.status == 200) {
            if (result.data.status) {
               toast.success(result.data.message);

            }
            else {
               toast.error(result.data.message);
            }
         }
      }
      else {
         toast.error('At last one qty is needed');
      }
   }

   useEffect(() => {
      getList();



   }, []);


   return (
      <>

         {/* {user ? (
            user.details.temp_user == 1 ? (
               <Navigate to="/" replace={true} />
            ) : (
               ""
            )
         ) : (
            <Navigate to="/" replace={true} />
         )} */}


         <Header wishList={wishList} />
         <main>
            <section className="space-cls my-account-page">
               <div className="container">
                  <div className="my-account-inner">

                     <LeftSitebar />
                     <div className="myaccount_r">
                        <div className="wishlist_item">
                           <h2 className="heading-type2">{strings.myWishlist}</h2>
                           <div className="row">
                              {wishList.data ? wishList.data.length > 0 ?
                                 wishList.data.map((item, index) =>
                                    <div className="col_2 col-4 col-sm-4 col-md-6 col-lg-4 col-xl-3 col_5">
                                       <div className="arrival-prd">
                                          <div className="prd_remove">
                                             <Link to="#" onClick={() => deleteItem(item.product.id, "remove")}>
                                                <img src={ImgmCross} />
                                             </Link>
                                          </div>
                                          <div className="arrival-prd-img">
                                             {/* <a href="product-detail.html"> */}
                                             <Link to={'/product-detail/' + item.product.id} target="_blank">
                                                <img src={item.product ? item.product.image : ImgmA1} alt="" />
                                             </Link>
                                             {/* </a> */}
                                          </div>
                                          <div className="arrival-cont">
                                             <div className="sold_rate">
                                                <div className="ratting-wrap">
                                                   <span className="ratting-icon">
                                                      <img src={ImgmStar} alt="Star" />
                                                   </span>
                                                   <span className="rate-dtl">{item.product ? item.product.rating : 0} </span>
                                                </div>
                                                <div className="sold_dtl">
                                                   <p>{item.product.sold} {strings.sold} & {item.product.left} {strings.left}</p>
                                                </div>
                                             </div>
                                             <div className="arrival-title">
                                                <h3>{item.product ? item.product.brands ? item.product.brands.name : '' : ''} </h3>
                                                <p>{item.product ? item.product.name : ''}</p>
                                             </div>
                                             <div className="arrival-price">
                                                <p><Currency data={item.product.discount_price != 0 ? item.product.discount_price : item.product.original_price} /></p>
                                                {parseInt(item.product.discount_price) == 0 ? '' : <p className="discount-price">
                                                   <Currency data={item.product.original_price} /> ({(((item.product.original_price - item.product.discount_price) * 100) / item.product.original_price).toFixed(2)}% off)</p>}
                                                {/* <p className="discount-price">KWD {item.product? item.product.discount_prices :0} ({(((item.product.original_price-item.product.discount_price)*100)/item.product.original_price).toFixed(2)}% off)</p> */}
                                             </div>
                                          </div>
                                          <div className="prd-add-option">

                                             <div className="cart_wrap">
                                                {/* <Link onClick={() => addtoCart('addCart', item.product.id)} className="btn_primary">Add to cart</Link> */}
                                                <Link to={'/product-detail/' + item.product.id} className="btn_primary">{strings.addtoCart}</Link>
                                             </div>
                                          </div>
                                       </div>
                                    </div>) : <section className="space-cls no_image">
                                    <div className="container">
                                       <div className="cart_row">
                                          <div className='col-md-12'>
                                             <h3>
                                                <center>
                                                   <img src={noFav} alt="Cart Product" />
                                                </center>
                                             </h3>
                                          </div>
                                       </div>
                                    </div>
                                 </section> : <section className="space-cls no_image">
                                 <div className="container">
                                    <div className="cart_row">
                                       <div className='col-md-12'>
                                          <h3>
                                             <center>
                                                <img src={noFav} alt="Cart Product" />
                                             </center>
                                          </h3>
                                       </div>
                                    </div>
                                 </div>
                              </section>}

                           </div>
                        </div>
                     </div>

                  </div>
               </div>
            </section>
         </main>
         <Footer />
      </>
   )
}
export default connect(mapStateToProps, mapDispatchToProps)(Wishlist);
// export default Wishlist;