import OwlCarousel from 'react-owl-carousel3';
import { Link } from "react-router-dom";
import ImgGucci from '../../assets/img/gucci.png';
const BrandList = (Props) => {


  return (
    <>
      {Props.data.map((item, index) =>
        <div className="col_1">
          <Link to={'/product/brand/' + item.id}>

            <div className='brand_img'>
              <img src={item.image} alt={item.name} />
            </div>
            <div className='brand_name'>
              <h5>{item.name}</h5>
            </div>
          </Link>
        </div>
      )}
    </>

  )
}
export default BrandList;