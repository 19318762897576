import OwlCarousel from 'react-owl-carousel3';
import { Link } from "react-router-dom";
import ImgPrd2 from '../../assets/img/prd-2.png';
const HomeFragrance = (Props) =>{
    
    return (
        <>           

				<OwlCarousel className="owl-carousel  owl-theme" {...Props.options}  >
                    {Props.data.map((item, index) =>    
                     item.banner_type=='video' ?                     
                         <video width="100%" height={487} controls>
                                <source src={item.file} type="video/mp4"/>
                                <source src={item.file} type="video/ogg"/>
                                <source src={item.file} type="video/webm"/>
                                </video> :<div className="item">
                                    <div className="fragrances-box">
                                        <div className="fragrances-img">
                                            <Link to={"/product/sub/"+ item.id}>
                                            <img src={item.image} alt="" />
                                            </Link>
                                        </div>
                                        <div className="fragrances-title">
                                            <p>{item.name}</p>
                                        </div>
                                    </div>
                                </div>
                    )}
					</OwlCarousel> 
	      		
       </>

    )
}
export default HomeFragrance;