import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import OwlCarousel from 'react-owl-carousel3';
import Accordion from 'react-bootstrap/Accordion';

import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'

import { useParams } from 'react-router-dom';
import { useStateWithCallbackLazy } from 'use-state-with-callback';


import Header from '../layout/Header';
import Footer from '../layout/Footer';
import ProductList from './Component/ProductList';
import apiPath from "../constants/apiPath";
import Http from "../services/Http";
import toast, { Toaster } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import ImgBannerPrd from '../assets/img/banner-prd.png';
import ImgMBannerPrd from '../assets/img/m-banner-prd.png';

import ImgSortIc from '../assets/img/sort_ic.png';
import ImgFilterIc from '../assets/img/filter_ic.png';

import LocalizedStrings from "react-localization";
import localization from "../localization";
let strings = new LocalizedStrings(localization);



const options = {
   margin: 0,
   nav: true,
   dots: false,
   loop: true,
   responsive: {
      0: {
         items: 1,
         margin: 7.5
      },
      420: {
         items: 1
      },
      577: {
         items: 1
      },
      992: {
         items: 1
      }
   }
};

const brandOption = {
   margin: 15,
   nav: true,
   dots: false,
   loop: true,
   responsive: {
      0: {
         items: 1.5,
         margin: 7.5
      },
      577: {
         items: 3
      },
      992: {
         items: 4
      },
      1366: {
         items: 5
      }
   }
}

const flowersOption = {
   margin: 15,
   nav: true,
   dots: false,
   loop: true,
   responsive: {
      0: {
         items: 2.3,
         margin: 7.5
      },
      577: {
         items: 3
      },
      992: {
         items: 5
      },
      1366: {
         items: 6
      }
   }
}

const comboOption = {
   margin: 15,
   nav: true,
   dots: false,
   loop: true,
   responsive: {
      0: {
         items: 1,
         margin: 7.5
      },
      420: {
         items: 2
      },
      577: {
         items: 2
      },
      992: {
         items: 2
      },
      1366: {
         items: 3
      }
   }
}

const DiscountOption = {
   margin: 15,
   nav: true,
   dots: false,
   loop: true,
   responsive: {
      0: {
         items: 1,
         margin: 7.5
      },
      420: {
         items: 2
      },
      577: {
         items: 2
      },
      992: {
         items: 3
      },
      1366: {
         items: 4
      }
   }
}


const Sale = (Props) => {
   const listInnerRef = useRef();
   const [lastList, setLastList] = useState(false);
   const params = useParams();
   const [open, setOpen] = React.useState(false);
   const [filters, setFilters] = useState([]);
   const [product, setProduct] = useState([]);
   const [comman, setComman] = useStateWithCallbackLazy({
      page: 1,
      last_page: 0,
      showLoad: 'showCl',
      minPrice: 0,
      maxPrice: 0,
      sort: 'A_TO_Z',
      category: [],
      brand: [],
      rating: 0,
      notes: [],
      saleSetting: '',
   });

   const [currPage, setCurrPage] = useState(1); // storing current page number
   const [prevPage, setPrevPage] = useState(0); // storing prev page number
   const [userList, setUserList] = useState([]); // storing list
   const [wasLastList, setWasLastList] = useState(false);
   const handleOpen = () => {
      setOpen(!open);
   };

   const [currentLang, setCurrentLang] = useState(
      localStorage.getItem("current_lang")
         ? localStorage.getItem("current_lang")
         : "en"
   );
   strings.setLanguage(currentLang);


   const getProduct = async (page, value) => {
      let path = apiPath.saleProduct;
      var data = new FormData();
      data.append('page', page);
      if (comman.brand.length > 0) {
         data.append('brand_id', comman.brand);
      }

      if (params.typeId == undefined && params.type != '' && params.type != undefined && comman.category.length <= 0 && comman.brand.length <= 0) {
         data.append('category_slug', params.type);
      }

      if (params.type == 'brand' && params.typeId != '') {
         data.append('brand_id', params.typeId);
      }
      if (params.type == 'sub' && params.typeId != '') {
         data.append('sub_category_id', params.typeId);
      }

      if (comman.category.length > 0) {

         data.append('category_id', comman.category);
      }
      if (comman.notes.length > 0) {
         data.append('notes_id', comman.notes);
      }
      if (comman.rating > 0) {
         data.append('rating', comman.rating);
      }
      if (params.type == "price") {
         data.append('price', params.typeId);
      }
      if (comman.maxPrice > 0) {
         data.append('price', comman.maxPrice);
      }
      if (comman.sort) {
         data.append('order_by', comman.sort);

      }
      else {

      }


      let options = {
         method: 'POST',
         //				headers: { 'language': this.state.language },
         data: data
      }

      let result = await Http(path, options);
      if (result.status === 200) {
         if (result.data.status) {
            let resultJson = result.data.result;
            /*if (!result.data.data.data.length) {
               setLastList(true);
               return;
            }998
            /*setPrevPage(currPage);
            setProduct({
               data: result.data.data.data,
            })*/
            //if (!lastList && prevPage !== currPage) {
            //getProduct();
            //}
            if (page == 1) {
               setProduct({
                  data: result.data.data.data,
               })
               setComman({ ...comman, last_page: result.data.data.last_page });
            }
            else {
               setProduct({
                  data: product.data.concat(result.data.data.data)
               })
            }

            if (page === comman.last_page) {
               setComman({ ...comman, showLoad: 'hideCl' });
            }
         } else {

            setComman({ ...comman, saleSetting: result.data.data })
            toast.error(result.data.message);
         }
      } else {
         toast.error(result.statusText);
      }
   }

   const getFilterData = async () => {
      let path = apiPath.getFilterData;
      var data = new FormData();
      data.append('type', 'sale');
      let options = {
         method: 'POST',
         data: data
         //				headers: { 'language': this.state.language },
      }

      let result = await Http(path, options);

      if (result.status === 200) {
         if (result.data.status) {
            setFilters({
               data: result.data.data,
            })
            // alert(filter.data.category)
         } else {
            toast.error(result.data.message);
         }
      } else {
         toast.error(result.statusText);
      }
   }

   const handleOnFilter = async (e, type, value) => {
      // alert(e.target.name)
      // alert(e.target.value)
      if (type === 'sort') {
         await setComman({ sort: e.target.value })
         await getProduct(1, comman)
         await setComman({ sort: e.target.value })
      }
      if (type === 'category') {
         if (e.target.checked === true) {
            comman.category.push(value);
         }
         else {
            comman.category.pop(value);
         }
         setComman({ ...comman, category: [...new Set(comman.category)] });
         getProduct(1, comman)

      }

      if (type === 'brand') {
         if (e.target.checked === true) {
            comman.brand.push(value);
         }
         else {
            comman.brand.pop(value);
         }
         setComman({ ...comman, brand: [...new Set(comman.brand)] });
         getProduct(1, comman)
      }
      if (type === 'rating') {
         setComman({ ...comman, rating: value });
         getProduct(1, comman)
         await setComman({ ...comman, rating: value });
      }
      if (type === 'notes') {
         if (e.target.checked === true) {
            comman.notes.push(value);
         }
         else {
            comman.notes.pop(value);
         }
         setComman({ ...comman, notes: [...new Set(comman.notes)] });
         getProduct(1, comman)

      }
      //   
      // await getProduct(1, comman)

   }

   const handleFavourite = async (e, id, type, index) => {

      let path = apiPath.AddRemoveWishlist;
      var data = new FormData()
      data.append('product_id', id);
      data.append('type', type);
      let options = {
         method: 'POST',
         data: data
      }

      let result = await Http(path, options);
      if (result.status === 200) {
         if (result.data.status) {
            var is_wish = product.data[index].is_wishlist;
            var is_wish_val = 0;

            if (type == 'add') {
               setProduct({ ...product, [product.data[index].is_wishlist]: 0 });
            }
            else {
               setProduct({ ...product, [product.data[index].is_wishlist]: 1 });
            }

            toast.success(result.data.message);
         } else {
            toast.error(result.data.message);
         }
      } else {
         toast.error(result.statusText);
      }


   }
   const onScroll = () => {
      if (listInnerRef.current) {
         const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
         if (scrollTop + clientHeight === scrollHeight) {
            alert(currPage);
            setCurrPage(currPage + 1);
         }
      }
   };
   useEffect(() => {
      // window.scrollTo(0, 0)
      getProduct(1);
      getFilterData();

   }, []);



   /*
   
     window.scroll(function() {
      alert('tst');
       
   /*   var isMobile = {
        Android: function() {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function() {
            return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
        },
        any: function() {
            return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
        }
      };  
      if(!isMobile.any()){ 
        if($(window).scrollTop() + $(window).height() >= $(document).height()) {
          if(page == parseInt(lastPage)){
            page = 1;
          }else{
            page++;
          } 
          loadMoreData(page,category_id,country,star_ads);          
        }
      }else{
        if($(window).scrollTop() + ($(window).height() + 100) >= $(document).height()) {
          if(page == parseInt(lastPage)){
            page = 1;
          }else{
            page++;
          } 
          loadMoreData(page,category_id,country,star_ads);          
        }
      } * 
    });*/




   const loadMore = (data) => {
      var page = comman.page
      page = page + 1;
      setComman({ ...comman, page: page });
      getProduct(page);
   }

   const priceChange = (value) => {
      setComman({ ...comman, maxPrice: value });
      getProduct(1, comman);


   }



   const addClass = (e, type) => {
      if (type == "short") {
         document.getElementsByTagName('body')[0].setAttribute("class", 'open_sort');
      }
      else {
         document.getElementsByTagName('body')[0].setAttribute("class", 'open_filter');
      }

      // alert(type)


   }


   const removeClass = (f, type) => {
      if (type == "short") {
         document.getElementsByTagName('body')[0].setAttribute("class", '');
      }
      else {
         document.getElementsByTagName('body')[0].setAttribute("class", '');
      }

      // alert(type)


   }

   return (
      <>
         <Header />
         <main>

            {/* <section className="space-cls banner-sec">
               <div className="container">
                  <OwlCarousel className="owl-carousel  owl-theme" {...options}  >
                     <div className="item">
                        <div className="banner-img">
                           <picture>
                              <source media="(min-width:575.98px)" srcset={ImgBannerPrd} />
                              <img src={ImgMBannerPrd} alt="" />
                           </picture>
                        </div>
                     </div>
                     <div className="item">
                        <div className="banner-img">
                           <picture>
                              <source media="(min-width:575.98px)" srcset={ImgBannerPrd} />
                              <img src={ImgMBannerPrd} alt="" />
                           </picture>
                        </div>
                     </div>
                     <div className="item">
                        <div className="banner-img">
                           <picture>
                              <source media="(min-width:575.98px)" srcset={ImgBannerPrd} />
                              <img src={ImgMBannerPrd} alt="" />
                           </picture>
                        </div>
                     </div>
                  </OwlCarousel>
               </div>
            </section> */}
            {filters.data ?
               <section className="space-cls banner-sec">
                  <div className="container">
                     {filters.data.limited_product.length > 0 &&
                        <OwlCarousel className="owl-carousel  owl-theme" {...options}  >
                           {filters.data.limited_product.map((option, index) =>
                              <div className="item">
                                 <div className="banner-img">
                                    <a href={'/product/' + (option.type != "product" ? option.type : "product-detail") + "/" + option.banner_for_id}>
                                       <picture>
                                          <source media="(min-width:575.98px)" srcSet={option.file} />
                                          <img src={option.file} alt="" />
                                       </picture>
                                    </a>
                                 </div>
                              </div>
                           )}
                           {/* {filters.data.limited_product.map((option, index) => { */}
                           {/* <div className="item">
                              <div className="banner-img">
                                 <picture>
                                    <source media="(min-width:575.98px)" srcSet={option.file} />
                                    <img src={option.file} alt="" />
                                 </picture>
                              </div>
                           </div> */}

                           {/* <div className="item">
                        <div className="banner-img">
                           <picture>
                              <source media="(min-width:575.98px)" srcSet={ImgBannerPrd} />
                              <img src={ImgMBannerPrd} alt="" />
                           </picture>
                        </div>
                     </div>
                     <div className="item">
                        <div className="banner-img">
                           <picture>
                              <source media="(min-width:575.98px)" srcSet={ImgBannerPrd} />
                              <img src={ImgMBannerPrd} alt="" />
                           </picture>
                        </div>
                     </div> */}
                           {/* })} */}
                        </OwlCarousel>
                     }

                  </div>
               </section> : null}

            <section className="space-cls product_listing">
               <div className="container">
                  <div className="product_wrap" onScroll={onScroll}
                     ref={listInnerRef}>
                     <div className="product_left_s">
                        <div className="filter_main">
                           <div className="filter_pop d-md-none">
                              <a className="short_icon" id="new" onClick={(e) => addClass(e, "short")} ><img src={ImgSortIc} /> Short</a>
                              <a className="filter_icon" id="new1" onClick={(e) => addClass(e, "filter")} ><img src={ImgFilterIc} /> Filter</a>
                           </div>
                        </div>
                        {comman.saleSetting && comman.saleSetting.sale_status == 1 ?
                           <div className="side-filter">

                              <Accordion defaultActiveKey="0" className="sort_by">
                                 <button type="button" class="btn_cross d-md-none" onClick={(f) => removeClass(f, "short")}>
                                    <img src="../img/close.png" alt="Close" />
                                 </button>
                                 <Accordion.Item eventKey="0">
                                    <Accordion.Header>{strings.short_by}</Accordion.Header>
                                    <Accordion.Body>
                                       <div className="filter_cont_s">
                                          <label className="custom_radio_b">
                                             <input type="radio" name="sort" value="A_TO_Z" checked={comman.sort === 'A_TO_Z'} onChange={(e) => handleOnFilter(e, 'sort', 'A_TO_Z')} />
                                             <span className="checkmark"></span>A To Z
                                          </label>
                                          <label className="custom_radio_b">
                                             <input name="sort" type="radio" value="Z_TO_A" checked={comman.sort === 'Z_TO_A'} onChange={(e) => handleOnFilter(e, 'sort', 'Z_TO_A')} />
                                             <span className="checkmark"></span>Z To A
                                          </label>
                                       </div>
                                    </Accordion.Body>
                                 </Accordion.Item>
                              </Accordion>
                              <Accordion defaultActiveKey="1" className="filter_info">
                                 <div className="filter_mob_open">
                                    <button type="button" class="btn_cross d-md-none" onClick={(f) => removeClass(f, "filter")}>
                                       <img src="../img/close.png" alt="Close" />
                                    </button>
                                    <Accordion.Item eventKey="1">
                                       <Accordion.Header>{strings.category}</Accordion.Header>
                                       <Accordion.Body>
                                          <div className="filter_cont_s">


                                             {filters.data && filters.data.category.map((option, index) => {

                                                return (<label className="custom_checkbox">
                                                   <input type="checkbox" value={option.id} onClick={(e) => handleOnFilter(e, 'category', option.id)} />
                                                   <span className="checkmark"></span>
                                                   {option.name}
                                                   <span className="filter_meta">{option.total_product}</span>
                                                </label>)
                                             })}


                                          </div>
                                       </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                       <Accordion.Header>{strings.brands}</Accordion.Header>
                                       <Accordion.Body>
                                          <div className="filter_cont_s">
                                             {filters.data && filters.data.brand.map((option, index) => {
                                                return (<label className="custom_checkbox">
                                                   <input type="checkbox" value={option.id} onClick={(e) => handleOnFilter(e, 'brand', option.id)} />
                                                   <span className="checkmark"></span>
                                                   {option.name}
                                                   <span className="filter_meta">{option.total_product}</span>
                                                </label>)
                                             })}


                                          </div>
                                       </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                       <Accordion.Header>{strings.rating}</Accordion.Header>
                                       <Accordion.Body>
                                          <div className="filter_cont_s">
                                             <label className="custom_radio_b">
                                                <input type="radio" name="rating" value="5" checked={comman.rating === 5 ? true : false} onClick={(e) => handleOnFilter(e, 'rating', 5)} />
                                                <span className="checkmark"></span>{strings.start45}
                                             </label>

                                             <label className="custom_radio_b">
                                                <input type="radio" name="rating" value="4" checked={comman.rating === 4 ? true : false} onClick={(e) => handleOnFilter(e, 'rating', 4)} />
                                                <span className="checkmark"></span>{strings.start34}
                                             </label>

                                             <label className="custom_radio_b">
                                                <input type="radio" name="rating" value="3" checked={comman.rating === 3 ? true : false} onClick={(e) => handleOnFilter(e, 'rating', 3)} />
                                                <span className="checkmark"></span>{strings.start23}
                                             </label>

                                             <label className="custom_radio_b">
                                                <input type="radio" name="rating" value="2" checked={comman.rating === 2 ? true : false} onClick={(e) => handleOnFilter(e, 'rating', 2)} />
                                                <span className="checkmark"></span>{strings.start12}
                                             </label>

                                             <label className="custom_radio_b">
                                                <input type="radio" name="rating" value="1" checked={comman.rating === 1 ? true : false} onClick={(e) => handleOnFilter(e, 'rating', 1)} />
                                                <span className="checkmark"></span>{strings.start01}
                                             </label>
                                          </div>
                                       </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                       <Accordion.Header>{strings.price}</Accordion.Header>
                                       <Accordion.Body>
                                          <div className="filter_cont_s">


                                             <Slider
                                                min={0}
                                                max={filters.data ? filters.data.max_price : 0}
                                                value={comman.maxPrice}
                                                onChange={priceChange}
                                             />

                                          </div>
                                       </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                       <Accordion.Header>Notes</Accordion.Header>
                                       <Accordion.Body>
                                          <div className="filter_cont_s">


                                             {filters.data && filters.data.note_image.map((option, index) => {

                                                return (<label className="custom_checkbox">
                                                   <input type="checkbox" value={option.id} onClick={(e) => handleOnFilter(e, 'notes', option.id)} />
                                                   <span className="checkmark"></span>
                                                   {option.name}
                                                </label>)
                                             })}


                                          </div>
                                       </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                       <Accordion.Header>{strings.perfume_accords}</Accordion.Header>
                                       <Accordion.Body>
                                          <div className="filter_cont_s">


                                             {filters.data && filters.data.notes.map((option, index) => {



                                                return (<label className="custom_checkbox  custom_checkbox_accords" style={{ 'background': option.color }}>
                                                   <input type="checkbox" value={option.id} onClick={(e) => handleOnFilter(e, 'notes', option.id)} />
                                                   <span className="checkmark checkmark_accords" ></span>
                                                   {option.name}
                                                </label>)
                                             })}


                                          </div>
                                       </Accordion.Body>
                                    </Accordion.Item>
                                 </div>
                              </Accordion>


                              <div className="" id="accordionExample1">
                                 <div>
                                    {/* <select className='accordion-button'>

                                    <option>Please choose one option</option>
                                    {filters.data && filters.data.category.map((option, index) => {
                                       return <option key={option.id} >
                                          {option.name}
                                       </option>
                                    })}
                                 </select>
                                 <button type="button" className="btn_cross d-md-none">
                                <img src={{ImgClose}} alt="Close"/>    
                              </button>
                               <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingTwo">
                                     <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                     Category
                                     </button>
                                  </h2>
                                  <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">
                                     <div className="accordion-body">
                                        <div className="accordion" id="accordionExample2">
                                           <div className="accordion-item">
                                              <h2 className="accordion-header" id="mensFragrance">
                                                 <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseInnerOne" aria-expanded="true" aria-controls="collapseInnerOne">
                                                 Mens Fragrance
                                                 </button>
                                              </h2>
                                              <div id="collapseInnerOne" className="accordion-collapse collapse show" aria-labelledby="mensFragrance" data-bs-parent="#accordionExample2">
                                                 <div className="accordion-body">
                                                    <div className="filter_cont_s">
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Perfumes
                                                       <span className="filter_meta">1636</span>
                                                       </label>
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Deodorants/Roll-Ons
                                                       <span className="filter_meta">1636</span>
                                                       </label>
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Body Mist/Spray
                                                       <span className="filter_meta">1636</span>
                                                       </label>
                                                    </div>
                                                 </div>
                                              </div>
                                           </div>
                                           <div className="accordion-item">
                                              <h2 className="accordion-header" id="WomensFragrance">
                                                 <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseInnerTwo" aria-expanded="true" aria-controls="collapseInnerTwo">
                                                 Womens Fragrance
                                                 </button>
                                              </h2>
                                              <div id="collapseInnerTwo" className="accordion-collapse collapse" aria-labelledby="WomensFragrance" data-bs-parent="#accordionExample2">
                                                 <div className="accordion-body">
                                                    <div className="filter_cont_s">
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       24/7 electricity
                                                       </label>
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Inverter
                                                       </label>
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Luxury
                                                       </label>
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Party Homes
                                                       </label>
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Super Host
                                                       </label>
                                                    </div>
                                                 </div>
                                              </div>
                                           </div>
                                           <div className="accordion-item">
                                              <h2 className="accordion-header" id="HomeFragrance">
                                                 <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseInnerThree" aria-expanded="true" aria-controls="collapseInnerThree">
                                                 Home Fragrance
                                                 </button>
                                              </h2>
                                              <div id="collapseInnerThree" className="accordion-collapse collapse" aria-labelledby="HomeFragrance" data-bs-parent="#accordionExample2">
                                                 <div className="accordion-body">
                                                    <div className="filter_cont_s">
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       24/7 electricity
                                                       </label>
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Inverter
                                                       </label>
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Luxury
                                                       </label>
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Party Homes
                                                       </label>
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Super Host
                                                       </label>
                                                    </div>
                                                 </div>
                                              </div>
                                           </div>
                                           <div className="accordion-item">
                                              <h2 className="accordion-header" id="VintageFragrance">
                                                 <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseInnerFour" aria-expanded="true" aria-controls="collapseInnerFour">
                                                 Vintage Collection
                                                 </button>
                                              </h2>
                                              <div id="collapseInnerFour" className="accordion-collapse collapse" aria-labelledby="VintageFragrance" data-bs-parent="#accordionExample2">
                                                 <div className="accordion-body">
                                                    <div className="filter_cont_s">
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       24/7 electricity
                                                       </label>
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Inverter
                                                       </label>
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Luxury
                                                       </label>
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Party Homes
                                                       </label>
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Super Host
                                                       </label>
                                                    </div>
                                                 </div>
                                              </div>
                                           </div>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                               <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingThree">
                                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                     Brands
                                     </button>
                                  </h2>
                                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample1">
                                     <div className="accordion-body">
                                        <div className="filter_cont_s">
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           Gucci
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           Ck
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           Puma
                                           </label>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                               <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingFour">
                                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                     Price
                                     </button>
                                  </h2>
                                  <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample1">
                                     <div className="accordion-body">
                                        <div className="filter_cont_s">
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 0 - KWD 50
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" checked=""/>
                                           <span className="checkmark"></span>
                                           KWD 51 - KWD 100
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 101 - KWD 150
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 151 - KWD 200
                                           </label>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                               <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingFive">
                                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                     Discount
                                     </button>
                                  </h2>
                                  <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample1">
                                     <div className="accordion-body">
                                        <div className="filter_cont_s">
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 0 - KWD 50
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" checked=""/>
                                           <span className="checkmark"></span>
                                           KWD 51 - KWD 100
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 101 - KWD 150
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 151 - KWD 200
                                           </label>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                               <div className="accordion-item">
                                  <h2 className="accordion-header " id="headingSix">
                                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                     Rating
                                     </button>
                                  </h2>
                                  <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                     <div className="accordion-body">
                                        <div className="filter_cont_s">
                                           <label className="custom_radio_b">
                                           <input type="radio" checked="checked" name="radio"/>
                                           <span className="checkmark"></span>All
                                           </label>
                                           <label className="custom_radio_b">
                                           <input type="radio" name="radio"/>
                                           <span className="checkmark"></span>4 Stars & Above 601
                                           </label>
                                           <label className="custom_radio_b">
                                           <input type="radio" name="radio"/>
                                           <span className="checkmark"></span>3 Stars & Above 642
                                           </label>
                                           <label className="custom_radio_b">
                                           <input type="radio" name="radio"/>
                                           <span className="checkmark"></span>2 Stars & Above 643
                                           </label>
                                           <label className="custom_radio_b">
                                           <input type="radio" name="radio"/>
                                           <span className="checkmark"></span>1 Star & Above 643 
                                           </label>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                               <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingFive">
                                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                     Size
                                     </button>
                                  </h2>
                                  <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample1">
                                     <div className="accordion-body">
                                        <div className="filter_cont_s">
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 0 - KWD 50
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" checked=""/>
                                           <span className="checkmark"></span>
                                           KWD 51 - KWD 100
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 101 - KWD 150
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 151 - KWD 200
                                           </label>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                               <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingFive">
                                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                     Concentration Type
                                     </button>
                                  </h2>
                                  <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample1">
                                     <div className="accordion-body">
                                        <div className="filter_cont_s">
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 0 - KWD 50
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" checked=""/>
                                           <span className="checkmark"></span>
                                           KWD 51 - KWD 100
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 101 - KWD 150
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 151 - KWD 200
                                           </label>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                               <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingFive">
                                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                     Notes
                                     </button>
                                  </h2>
                                  <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample1">
                                     <div className="accordion-body">
                                        <div className="filter_cont_s">
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 0 - KWD 50
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" checked=""/>
                                           <span className="checkmark"></span>
                                           KWD 51 - KWD 100
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 101 - KWD 150
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 151 - KWD 200
                                           </label>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                               <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingFive">
                                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                     Perfume Accords
                                     </button>
                                  </h2>
                                  <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample1">
                                     <div className="accordion-body">
                                        <div className="filter_cont_s">
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 0 - KWD 50
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" checked=""/>
                                           <span className="checkmark"></span>
                                           KWD 51 - KWD 100
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 101 - KWD 150
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                             KWD 151 - KWD 200
                                           </label>
                                        </div>
                                     </div>
                                  </div>
                               </div> */}
                                 </div>
                              </div>
                           </div> : null}
                     </div>
                     <div className="product_right_s">
                        <div className="row">

                           {comman.saleSetting == '' ?
                              product.data ? <ProductList sale={true} data={product.data} onScroll={onScroll}
                                 listInnerRef={listInnerRef} handleFavourite={handleFavourite} /> : <Skeleton height={100} count={10} /> : <img src={comman.saleSetting && comman.saleSetting.sale_status == 0 ? comman.saleSetting.sale_start_banner : comman.saleSetting.sale_end_banner} />}
                        </div>

                        {/* <div className={"load-more d-flex justify-content-center mt-2 " + comman.showLoad}>

                           <button type="button" className="btn btn_primary" onClick={loadMore}>Load More</button>
                        </div> */}

                        {/*<div className="sale-sec">
                     <OwlCarousel className="owl-carousel  owl-theme" {...flowersOption}  > 
                
                          <div className="item">
                            <div className="sale-prd">
                              <a href="#">
                                <img src={ImgmSale1} alt=""/>
                              </a>
                            </div>
                          </div>
                          <div className="item">
                            <div className="sale-prd">
                              <a href="#">
                                <img src={ImgmSale2} alt=""/>
                              </a>
                            </div>
                          </div>
                          <div className="item">
                            <div className="sale-prd">
                              <a href="#">
                                <img src={ImgmSale3} alt=""/>
                              </a>
                            </div>
                          </div>
                          <div className="item">
                            <div className="sale-prd">
                              <a href="#">
                                <img src={ImgmSale4} alt=""/>
                              </a>
                            </div>
                          </div>
                          <div className="item">
                            <div className="sale-prd">
                              <a href="#">
                                <img src={ImgmSale1} alt=""/>
                              </a>
                            </div>
                          </div>
                          <div className="item">
                            <div className="sale-prd">
                              <a href="#">
                                <img src={ImgmSale2} alt=""/>
                              </a>
                            </div>
                          </div>
                          <div className="item">
                            <div className="sale-prd">
                              <a href="#">
                                <img src={ImgmSale3} alt=""/>
                              </a>
                            </div>
                          </div>
                          <div className="item">
                            <div className="sale-prd">
                              <a href="#">
                                <img src={ImgmSale4} alt=""/>
                              </a>
                            </div>
                          </div>
                        
                        </OwlCarousel>
    </div>*/}
                     </div>
                  </div>
               </div>
            </section>
         </main>
         <Footer />
      </>
   )
}

export default Sale;