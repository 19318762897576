import React, { useState, useEffect } from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import ImgTick from '../assets/img/tick.png';
import { Link, Navigate, useParams } from "react-router-dom";


import { userLogin } from "../actions/index";
import { connect } from "react-redux";

import LocalizedStrings from "react-localization";
import localization from "../localization";
let strings = new LocalizedStrings(localization);


const mapStateToProps = state => {
   return { user: state.user };
};

const mapDispatchToProps = dispatch => {
   return {
      userLogin: user => dispatch(userLogin(user))
   };
}

const Thankyou = (Props) => {

   const params = useParams();
   const [user, setUser] = useState(Object.keys(Props.user).length > 0 ? Props.user : null)
   const [currentLang, setCurrentLang] = useState(
      localStorage.getItem("current_lang")
         ? localStorage.getItem("current_lang")
         : "en"
   );
   strings.setLanguage(currentLang);
   useEffect((e) => {
      window.scrollTo(0, 0)
   }, [])


   return (
      <>
         {/* {user ? user.details.temp_user == 1 ? <Navigate to="/" replace={true} /> : '' : <Navigate to="/" replace={true} />} */}
         <Header />
         <main>
            <section className="thankyou-sec">
               <div className="container">
                  <div className="thankyou_sec_in">
                     <img src={ImgTick} alt="check icon" />
                     <h1>{strings.thankyou}</h1>
                     <p>{strings.your_order} :<b> {params.orderId}</b> {strings.success_place_with_us}</p>
                     <div className="btn_thank">
                        <Link className="btn btn_primary" to="/">{strings.back_to_home}</Link>
                     </div>
                  </div>
               </div>
            </section>
         </main>
         <Footer />
      </>
   )
}
export default connect(mapStateToProps, mapDispatchToProps)(Thankyou);