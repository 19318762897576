
import { useEffect } from 'react';

function ScrollToTop() {
    const scrollToTop = () => {
        const scrollStep = window.pageYOffset / 50;

        const scrollAnimation = () => {
            const scrollTop = window.pageYOffset - scrollStep;
            if (window.pageYOffset <= 0) {
                return;
            }
            window.scroll(0, scrollTop);
            window.requestAnimationFrame(scrollAnimation);
        };
        scrollAnimation();
    };

    useEffect(() => {
        console.log("scroll again");
        scrollToTop();
    }, []);

    return null;
}

export default ScrollToTop;