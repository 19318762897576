import React, { useState, useEffect } from 'react';
import { Link, Navigate } from "react-router-dom";

import { useParams } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel3';
import ReactImageZoom from 'react-image-zoom';
import { Tab, Tabs, Dropdown, Button, Row, Col } from 'react-bootstrap';

import Header from '../layout/Header';
import Footer from '../layout/Footer';
import CommanList from '../view/Component/CommanList';

import apiPath from "../constants/apiPath";
import Http from "../services/Http";

import toast, { Toaster } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


import ImgPerfume1 from '../assets/img/perfume-1.jpg';
import ImgPerfume2 from '../assets/img/perfume-2.jpg';
import ImgPerfume3 from '../assets/img/perfume-3.jpg';
import ImgShare from '../assets/img/share.png';
import ImgmStar from '../assets/img/star.png';
import ImgmStar1 from '../assets/img/star-1.png';

import ImgProductTranding from '../assets/img/product-trending.png';
import ImgmFavorite from '../assets/img/favorite.png';
import ImgmOutofstock from '../assets/img/outofstock.png';
import ImgTrendingPrd from '../assets/img/trending-prd.png';

import ImgGucciLogo from '../assets/img/gucci_logo.png';
import ImgCheckout1 from '../assets/img/checkout-1.png';
import ImgCheckout2 from '../assets/img/checkout-2.png';
import ImgCheckout3 from '../assets/img/checkout-3.png';
import ImgPn1 from '../assets/img/pn-1.png';
import ImgPn3 from '../assets/img/pn-3.png';

import ImgBestSeller from '../assets/img/best-seller.png';
import ImgmBestSeller from '../assets/img/m-best-seller.png';


import ImgmA1 from '../assets/img/a1.png';
import ImgmA2 from '../assets/img/a2.png';
import ImgmA3 from '../assets/img/a3.png';
import ImgmA4 from '../assets/img/a4.png';

import ImgmFacebook from '../assets/img/facebook.png';
import ImgmTwitter from '../assets/img/twitter.png';
import ImgWhatup from '../assets/img/whatup.png';
import noReview from '../assets/img/no-review.png';


import { userLogin } from "../actions/index";
import { connect } from "react-redux";
import moment from 'moment';
import ProgressBar from './Component/progress-bar.component';
import Product from './Component/Product';
import Currency from './Component/Currency';

import LocalizedStrings from "react-localization";
import localization from "../localization";
import useScript from '../constants/useScript';
let strings = new LocalizedStrings(localization);


const mapStateToProps = state => {
   return { user: state.user };
};

const mapDispatchToProps = dispatch => {
   return {
      userLogin: user => dispatch(userLogin(user))
   };
}




const options = {
   margin: 0,
   nav: true,
   dots: false,
   loop: true,
   responsive: {
      0: {
         items: 1,
         margin: 7.5
      },
      420: {
         items: 1
      },
      577: {
         items: 1
      },
      992: {
         items: 1
      }
   }
};


const newAriveOption = {
   margin: 15,
   nav: true,
   dots: false,
   loop: false,
   responsive: {
      0: {
         items: 2,
         margin: 7.5
      },
      420: {
         items: 2,
         margin: 7.5
      },
      577: {
         items: 3,
         margin: 7.5
      },
      767.98: {
         items: 4
      },
      992: {
         items: 5
      },
      1366: {
         items: 6
      },
      1480: {
         items: 6
      },
      1580: {
         items: 7
      }
   }
}


const testData = [
   { bgcolor: "#6a1b9a", completed: 60 },
   { bgcolor: "#00695c", completed: 30 },
   { bgcolor: "#ef6c00", completed: 53 },
];

const ProductDetail = (Props) => {
   useScript('https://checkout.tabby.ai/tabby-promo.js');

   const params = useParams()


   const [user, setUser] = useState(Object.keys(Props.user).length > 0 ? Props.user : null)


   const [comman, setComman] = useState({
      zoomWidth: 500,
      img: ImgPerfume1,
      qty: 1,
      addCart: false,
      buyNow: false,

   });
   const [productDetails, setProductDetails] = useState([]);
   const [productRating, setProductRating] = useState([]);
   const [ratingData, setratingData] = useState([]);
   const [attr, setAttr] = useState([]);

   const [currentLang, setCurrentLang] = useState(
      localStorage.getItem("current_lang")
         ? localStorage.getItem("current_lang")
         : "en"
   );
   strings.setLanguage(currentLang);



   const changeImag = (e, item) => {

      setComman({ ...comman, img: item.image });
   }

   const current_currency = localStorage.getItem('current_currency') ? JSON.parse(localStorage.getItem('current_currency')) : { currency: 1, country_code: 'KWD' };

   const getProductDetails = async (id, type) => {
      let path = apiPath.productDetails + '/' + id + type;
      var data = new FormData();
      let options = {
         method: 'GET',
      }
      let result = await Http(path, options);
      if (result.status == 200) {
         if (result.data.status) {
            await setProductDetails({ data: result.data.data })

            setAttr(result.data.data.product.attribute_value_id.toString());

            if (result.data.data.cart_item != 0) {
               setComman({ ...comman, qty: parseInt(result.data.data.cart_item), img: result.data.data.product.image });
            }
            else {
               setComman({ ...comman, img: result.data.data.product.image });
            }

            // getRatingData(productDetails)
            let price = result.data.data.product_sale ? (result.data.data.product_sale.discount_price != 0 ? result.data.data.product_sale.discount_price : result.data.data.product_sale.original_price) : (result.data.data.product.discount_price != 0 ? result.data.data.product.discount_price : result.data.data.product.original_price);

            new window.TabbyPromo({
               selector: '#tabbyCard',
               lang: localStorage.getItem("current_lang") ? localStorage.getItem("current_lang") : "en",
               currency: current_currency.country_code,
               price: ((parseFloat(current_currency.currency) * price).toFixed(2)),
               productType: 'installments',
            });
         }
         else {
            toast.error(result.data.message);
         }
      }
   }
   const productShare = (e, id, title, type) => {
      if (type == 'facebook') {
         var url = window.location.href + 'product-detail/' + id
         window.open('https://facebook.com/sharer.php?u=' + url + '?id=' + id + '&quote=' + title);
      }
      if (type == 'twitter') {

         var url = window.location.href + 'product-detail/' + id
         window.open('https://twitter.com/share?url=' + url + '?id=' + id + '&text=' + title);
      }
      if (type == 'whatup') {
         var url = window.location.href + 'product-detail/' + id
         window.open('https://web.whatsapp.com/send?text=' + url);
      }
   }

   const chnageattributeValue = async (e, i) => {
      var attribute = attr.split(",");

      var old_attribute = attribute;

      attribute.map((item, index) => {
         if (i == index) {
            attribute[index] = parseInt(e.target.value)
         }
         else {
            attribute[index] = parseInt(item)
         }

      });


      let path = apiPath.ProductAvailability + '/' + params.id;
      var data = new FormData();
      data.append('attribute_value_id', attribute.toString());
      let options = {
         method: 'POST',
         data: data
      }
      let result = await Http(path, options);
      if (result.status == 200) {
         if (result.data.status) {
            setProductDetails({ data: result.data.data })
            setComman({ ...comman, img: result.data.data.product.image });
            setAttr(attribute.toString());
         }
         else {
            toast.error(result.data.message);
         }
      }
   }

   const getRatingReview = async (id) => {

      let path = apiPath.productRatingList;
      var data = new FormData();
      data.append('product_id', id)
      let options = {
         method: 'POST',
         data: data
      }
      let result = await Http(path, options);
      if (result.status == 200) {
         if (result.data.status) {

            setProductRating({ data: result.data.data })
            //   alert(productRating.data.length)
            //   setComman({ ...comman, img:result.data.data.product.image}); 
            //   setAttr(result.data.data.product.attribute_value_id);
         }
         else {
            // toast.error(result.data.message);
         }
      }
   }


   const addRemove = (e, type) => {
      var qty = 0;
      if (type == 'add') {
         if (productDetails.data.product.left > comman.qty) {
            qty = comman.qty + 1;
         }
         else {
            qty = comman.qty;
         }

      }
      else {
         if (comman.qty > 1) {
            qty = comman.qty - 1;
         }
      }
      let price = productDetails.data.product_sale ? (productDetails.data.product_sale.discount_price != 0 ? productDetails.data.product_sale.discount_price : productDetails.data.product_sale.original_price) : (productDetails.data.product.discount_price != 0 ? productDetails.data.product.discount_price : productDetails.data.product.original_price);

      new window.TabbyPromo({
         selector: '#tabbyCard',
         lang: localStorage.getItem("current_lang") ? localStorage.getItem("current_lang") : "en",
         currency: current_currency.country_code,
         price: ((parseFloat(current_currency.currency) * price * qty).toFixed(2)),
         productType: 'installments',
      });
      setComman({ ...comman, qty: qty });
   }

   const addtoCart = async (type) => {

      if (comman.qty > 0) {
         let path = apiPath.AddtoCart;
         var data = new FormData();
         data.append('product_id', productDetails.data.product.id);
         if (params.type != undefined) {
            data.append('is_sale', params.type);
         }
         data.append('qty', comman.qty);
         if (attr) {
            data.append('product_attribute_values_id', attr);
         }

         let options = {
            method: 'POST',
            data: data
         }
         let result = await Http(path, options);
         if (result.status == 200) {
            if (result.data.status) {

               if (type == 'BuyNow') {
                  setComman({ ...comman, buyNow: true });
               }
               else {
                  setComman({ ...comman, addCart: true });
               }
               // getProductDetails(params.id);
               toast.success(result.data.message);
            }
            else {
               toast.error(result.data.message);
            }
         }
      }
      else {
         toast.error(strings.at_last_one_qty);
      }
   }

   const getRatingData = async (data) => {

   }

   const notifyMe = async () => {
      let path = apiPath.notifyMe + '/' + params.id;
      var data = new FormData();
      let options = {
         method: 'GET',
      }
      let result = await Http(path, options);
      if (result.status == 200) {
         if (result.data.status) {
            toast.success(result.data.message);
         }
         else {
            toast.error(result.data.message);
         }
      }
   }


   useEffect(() => {
      // window.scrollTo(0, 0);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      getProductDetails(params.id, params.type ? '/' + params.type : '');
      getRatingReview(params.id)
   }, [params]);

   return (
      <>

         {comman.buyNow ? <Navigate to="/cart" replace={true} /> : ''}
         <Header data={comman.addCart} />
         <main className='prd_detail_space'>
            {productDetails.data ?
               <>
                  <section className="space-cls product_dtl_page">
                     <div className="container">
                        <div className="row">
                           <div className="col-md-12 col-lg-4">
                              <div className="product-slide-main">
                                 <div id='lens'></div>

                                 <div className='zoom_sld_main' style={{ 'position': "relative", 'z-index': '1' }}>
                                    <ReactImageZoom {...comman} />
                                 </div>
                                 <div id='result'></div>


                                 <OwlCarousel className="owl-carousel  owl-theme" {...newAriveOption} key={productDetails.data.product.product_images.length} >
                                    {productDetails.data.product.product_images.length > 0 ?
                                       productDetails.data.product.product_images.map((item, index) =>
                                          <div className="item" key={index}
                                             onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                changeImag(e, item);
                                             }}
                                          >
                                             <img className='slideshow-thumbnails active'

                                                src={item.image} />
                                          </div>
                                       ) : ''}

                                 </OwlCarousel>
                              </div>
                           </div>
                           <div className="col-md-12 col-lg-8">
                              <div className="prd-dtl-cont">
                                 <div className="prd-title">
                                    <h2>{productDetails.data ? productDetails.data.product.name : ''}</h2>
                                    <div className="share-option">
                                       <Dropdown>
                                          <Dropdown.Toggle id="dropdown-custom-components" className='shareBtn'>
                                             <img src={ImgShare} alt="Share" />
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu >
                                             <Dropdown.Item eventKey="1" onClick={(e) => productShare(e, productDetails.data ? productDetails.data.product.id : 0, productDetails.data ? productDetails.data.product.name : '', 'facebook')}>
                                                <img src={ImgmFacebook} /> {strings.facebook}
                                             </Dropdown.Item>
                                             <hr />
                                             <Dropdown.Item eventKey="2" onClick={(e) => productShare(e, productDetails.data ? productDetails.data.product.id : 0, productDetails.data ? productDetails.data.product.name : '', 'twitter')}>
                                                <img src={ImgmTwitter} /> {strings.twitter}
                                             </Dropdown.Item>
                                             <hr />
                                             <Dropdown.Item eventKey="3" onClick={(e) => productShare(e, productDetails.data ? productDetails.data.product.id : 0, productDetails.data ? productDetails.data.product.name : '', 'whatup')}>
                                                <img src={ImgWhatup} /> {strings.whatus}
                                             </Dropdown.Item>
                                          </Dropdown.Menu>
                                       </Dropdown>
                                       <a href="#">

                                       </a>
                                    </div>
                                 </div>
                                 <div className="prd-cont-sec">
                                    <div className="main-prd-left">
                                       {/* <p>{productDetails.data ? productDetails.data.product.description : ''}</p> */}
                                       <div className="product-inner-dtl">
                                          <ul>
                                             {productDetails.data && productDetails.data.product.trending_product ?
                                                <li>
                                                   <span><img src={ImgProductTranding} /></span>
                                                </li>
                                                : ''}
                                             {productDetails.data ? productDetails.data.product.rating > 0 ? <>
                                                <li>
                                                   <div className="prd-review">
                                                      <span><img src={ImgmStar} /></span>
                                                      <span>{productDetails.data ? productDetails.data.product.rating : 0}</span>
                                                   </div>
                                                </li> </> : null : null}
                                             <li>
                                                <span>{productDetails.data ? productDetails.data.product.sold > 0 ? productDetails.data.product.sold + " " + strings.sold + " &" : null : null}  {productDetails.data ? productDetails.data.product.left : 0} {strings.left}</span>
                                             </li>
                                          </ul>
                                       </div>
                                       <div className="prd-price-wrap">
                                          <div className="arrival-price">
                                             <p>
                                                {productDetails.data.product_sale ? <Currency data={productDetails.data.product_sale.discount_price != 0 ? productDetails.data.product_sale.discount_price : productDetails.data.product_sale.original_price} /> : <Currency data={productDetails.data.product.discount_price != 0 ? productDetails.data.product.discount_price : productDetails.data.product.original_price} />}
                                             </p>
                                             {productDetails.data.product_sale ? parseInt(productDetails.data.product_sale.discount_price) == 0 ? '' : <p className="discount-price"><span><Currency data={productDetails.data.product_sale.original_price} /></span> ({Math.round(((productDetails.data.product_sale.original_price - productDetails.data.product_sale.discount_price) * 100) / productDetails.data.product_sale.original_price).toFixed(2)}% off)</p> : parseInt(productDetails.data.product.discount_price) == 0 ? '' : <p className="discount-price"><span><Currency data={productDetails.data.product.original_price} /> </span>({Math.round(((productDetails.data.product.original_price - productDetails.data.product.discount_price) * 100) / productDetails.data.product.original_price).toFixed(2)}% off)</p>}

                                          </div>
                                          <div className="prd-brand">
                                             <div className="brand-img">
                                                <Link to={'/product/brand/' + (productDetails.data.product.brands != null ? productDetails.data.product.brands.id : '')}>
                                                   <span>
                                                      <img src={productDetails.data ? productDetails.data.product ? productDetails.data.product.brands ? productDetails.data.product.brands.image : ImgGucciLogo : ImgGucciLogo : ImgGucciLogo} /></span>
                                                </Link>
                                             </div>
                                          </div>
                                       </div>

                                       {productDetails.data ?
                                          productDetails.data.attributes.length > 0 ?
                                             productDetails.data.attributes.map((attribute, i) =>
                                                <div className="prd-size">
                                                   <h4>{attribute.name}</h4>
                                                   <div className="prd_size_label">
                                                      {attribute.attribute_values.length > 0 ?
                                                         attribute.attribute_values.map((attr_val, i1) =>

                                                            <label className="custom_radio_b">

                                                               <input type="radio" name={attr.id} value={attr_val.id} checked={attr ? attr.split(",").includes(attr_val.id.toString()) : ''} onClick={(e) => chnageattributeValue(e, i)} />
                                                               {productDetails.data ? (productDetails.data.product.attribute_value_id == attr_val.id) && (productDetails.data.product.status == 0) ? <del><span className="checkmark">{attr_val.value}</span></del> : <span className="checkmark">{attr_val.value}</span> : null}
                                                            </label>
                                                         ) : ''}
                                                   </div>

                                                </div>
                                             ) : '' : ""}
                                       <div id="field1" className="quantity_sec">
                                          <h4>{strings.qty}</h4>
                                          <div className="q_btn_group">
                                             <button type="button" id="sub" className="sub" onClick={(e) => addRemove(e, 'sub')}>-</button>
                                             <input type="number" id="1" value={comman.qty} min="1" max="3" />
                                             <button type="button" id="add" className="add" onClick={(e) => addRemove(e, 'add')}>+</button>
                                          </div>
                                       </div>
                                       <div id="tabbyCard" style={{marginTop: "10px"}}></div>
                                       <div className="add-btn-grp d-flex mt-3">

                                          {productDetails.data ? productDetails.data.cart_item == 0 ?

                                             productDetails.data.product.out_of_stock == 1 || productDetails.data.product.status == 0 ?
                                                <Button type="button" className="btn btn_primary me-2" disabled>{strings.out_of_stock}</Button>
                                                :
                                                productDetails.data.product.sold_out == 1 ?
                                                   <Button type="button" className="btn btn_primary me-2" disabled>{strings.sold_out}</Button>
                                                   :
                                                   productDetails.data.product.discontinue == 1 ?
                                                      <Button type="button" className="btn btn_primary me-2" disabled>{strings.discontinue}</Button> :
                                                      productDetails.data.product.left <= 0 ?
                                                         <Button type="button" className="btn btn_primary me-2" disabled>{strings.out_of_stock}</Button> :
                                                         comman.addCart ?
                                                            <Link to="/cart" className="btn btn_primary me-2">{strings.go_to_cart} </Link>
                                                            :
                                                            <Button type="button" onClick={() => addtoCart('addCart')} className="btn btn_primary me-2">{strings.add_to_bag}</Button>



                                             :
                                             productDetails.data.product.out_of_stock == 1 || productDetails.data.product.status == 0 ?
                                                <Button type="button" className="btn btn_primary me-2" disabled>{strings.out_of_stock}</Button>
                                                :
                                                productDetails.data.product.sold_out == 1 ?
                                                   <Button type="button" className="btn btn_primary me-2" disabled>{strings.sold_out}</Button>
                                                   :
                                                   productDetails.data.product.discontinue == 1 ?
                                                      <Button type="button" className="btn btn_primary me-2" disabled>{strings.discontinue}</Button>
                                                      :
                                                      productDetails.data.product.left <= 0 ?
                                                         <Button type="button" className="btn btn_primary me-2" disabled>{strings.out_of_stock}</Button> :
                                                         <Button className="btn btn_primary me-2" onClick={() => addtoCart('BuyNow')} >{strings.go_to_cart}</Button> : null}
                                          {/* <Link to="/cart" className="btn btn_primary me-2">Go To Cart </Link> : null} */}


                                          {productDetails.data && productDetails.data.product.out_of_stock == 1 || productDetails.data.product.status == 0 ?
                                             <Button type="button" className="btn btn_primary notify_btn me-2" onClick={() => notifyMe()}>{strings.notifyMe}</Button>
                                             :
                                             productDetails.data.product.sold_out == 1 ?
                                                <Button type="button" className="btn btn_primary notify_btn me-2" onClick={() => notifyMe()} >{strings.notifyMe}</Button>
                                                :
                                                productDetails.data.product.discontinue == 1 ?
                                                   <Button type="button" className="btn btn_primary notify_btn me-2" onClick={() => notifyMe()}>{strings.notifyMe}</Button>
                                                   :
                                                   productDetails.data.product.left <= 0 ?
                                                      <Button type="button" className="btn btn_primary notify_btn me-2" onClick={() => notifyMe()}>{strings.notifyMe}</Button> :
                                                      <Button href="#" onClick={() => addtoCart('BuyNow')} className="btn btn_primary">{strings.buyNow}</Button>}





                                       </div>
                                    </div>
                                    {productDetails.data.notes_color.length > 0 ?
                                       <div className="main-prd-right">
                                          <div className="perfume-acords">
                                             <h4>{strings.perfume_accords}</h4>
                                          </div>
                                          <div className="progress-wrap">


                                             {productDetails.data.notes_color.map((item, index) =>
                                                <div className="woody-bg" style={{ 'background': item.color, 'width': 100 - (8 * index) + '%' }}>
                                                   <span style={{ 'color': '#000' }}>{item.name}</span>
                                                </div>

                                             )}

                                          </div>
                                       </div>
                                       : null}
                                 </div>
                                 <div className='prd-dtl-bottom-cont'>
                                    <div className="checkout-delivery-wrap">
                                       <div className="checkout-col">
                                          <div className="checkout-box">
                                             <span className="c-icon">
                                                <img src={ImgCheckout1} alt="" />
                                             </span>
                                             <span>100% {strings.authenticate}</span>
                                          </div>
                                       </div>
                                       <div className="checkout-col">
                                          <div className="checkout-box">
                                             <span className="c-icon">
                                                <img src={ImgCheckout3} alt="" />
                                             </span>
                                             <span>{strings.free_shipping}</span>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="prd-inner-bg">
                                       <p>{strings.place_order_befoure}</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
                  <section className="accordion-sec space-cls">
                     <div className="container">
                        <div className="accordion-description">
                           <Row>
                              <Col md="6">
                                 <div className='prd-info'>
                                    <div className='prd-info-cont'>
                                       <h5>{strings.product_information}</h5>
                                       <ul>
                                          <li>
                                             <span>{strings.SKU} :</span>
                                             <span className="right-cont">{productDetails.data.product.sku}</span>
                                          </li>
                                          {productDetails.data.product.barcode != 0 ?
                                             <li>
                                                <span>{strings.barcode} :</span>
                                                <span className="right-cont">{productDetails.data.product.barcode}</span>
                                             </li> : null}
                                          <li>
                                             <span>{strings.brand} :</span>
                                             <span className="right-cont">{productDetails.data.product.brands != null ? productDetails.data.product.brands.name : ''}</span>
                                          </li>
                                          <li>
                                             <span>{strings.perfume_for} : </span>
                                             <span className="right-cont">{productDetails.data.product.categories.name}</span>
                                          </li>
                                          <li>
                                             <span>{strings.perfume_type} :</span>
                                             <span className="right-cont">{productDetails.data.product.sub_categories ? productDetails.data.product.sub_categories.name : ''}</span>
                                          </li>

                                       </ul>
                                    </div>
                                 </div>
                              </Col>
                              <Col md="6">
                                 <div className='prd-info'>
                                    <div className='prd-info-cont'>
                                       <h5>{strings.description}</h5>
                                       <p>{productDetails.data.product.description}</p>
                                    </div>
                                 </div>
                              </Col>
                           </Row>

                        </div>
                     </div>
                  </section>
                  {/*<section className="space-cls prd-inner-sec">
                     <div className="container">
                        <div className="prd-inner-bg">
                           <p>Bois Noir by Chanel is a Aromatic Fougere fragrance for men. Bois Noir was launched in 1987. The nose behind this fragrance is Jacques Polge.</p>
                        </div>
                     </div>
                                       </section>*/}
                  {productDetails.data ? productDetails.data.notes_image.length > 0 ?
                     <section className="space-cls prd-perfume-sec">
                        <div className="container">
                           <div className="perfume_inner_title">
                              <h3>{strings.perfume_notes}</h3>
                              <div className="show-votes">
                                 <div className="address-toggle">
                                    {/*<span>Show Notes</span>*/}
                                    {/* <Toggle
                           label="Toggle me"
                           toggled={true}
                           onClick={logState}
                        /> */}

                                 </div>
                              </div>
                           </div>
                           <div className="row">
                              {
                                 productDetails.data.notes_image.map((item, index) =>
                                    <div className="col-md-2">
                                       <div className="prd-perfume-box">
                                          <div className="prd-perfume-img">
                                             <img src={item.image} alt="" />
                                          </div>
                                          <h4>{item.name}</h4>
                                       </div>
                                    </div>

                                 )}


                           </div>
                        </div>
                     </section> : null : null}
                  {productDetails.data ? productDetails.data.product.rating > 0 ?
                     <section className="space-cls rating-main-sec">
                        <div className="container">
                           <div className="perfume_inner_title">
                              <h4>{strings.rating}</h4>
                           </div>
                           <div className="rating-sec">
                              <div className="rating-left">
                                 <span className="rating_title">{productDetails.data ? productDetails.data.product ? productDetails.data.product.rating : 0 : 0}</span>
                                 <span className="rating-ic">
                                    <img src={ImgmStar1} />
                                 </span>
                              </div>
                              {productDetails.data ? productDetails.data.ratings ? productDetails.data.ratings.total_rating_count > 0 ?
                                 <div className='rating-right'>
                                    <div className="App">
                                       <div className='rating-percent'>
                                          <span className="rating-no">5 <img src={ImgmStar1} /></span>
                                          <div className='rating-progress'>
                                             <ProgressBar bgcolor={"6a1b9a"} completed={productDetails.data ? productDetails.data.ratings ? ((productDetails.data.ratings.five * 100) / productDetails.data.ratings.total_rating_count) : 0 : 0} />
                                          </div>
                                       </div>
                                       <div className='rating-percent'>
                                          <span className="rating-no">4 <img src={ImgmStar1} /></span>
                                          <div className='rating-progress'>
                                             <ProgressBar completed={productDetails.data ? productDetails.data.ratings ? ((productDetails.data.ratings.four * 100) / productDetails.data.ratings.total_rating_count) : 0 : 0} />
                                          </div>
                                       </div>
                                       <div className='rating-percent'>
                                          <span className="rating-no">3 <img src={ImgmStar1} /></span>
                                          <div className='rating-progress'>
                                             <ProgressBar completed={productDetails.data ? productDetails.data.ratings ? ((productDetails.data.ratings.three * 100) / productDetails.data.ratings.total_rating_count) : 0 : 0} />
                                          </div>
                                       </div>
                                       <div className='rating-percent'>
                                          <span className="rating-no">2 <img src={ImgmStar1} /></span>
                                          <div className='rating-progress'>
                                             <ProgressBar completed={productDetails.data ? productDetails.data.ratings ? ((productDetails.data.ratings.two * 100) / productDetails.data.ratings.total_rating_count) : 0 : 0} />
                                          </div>
                                       </div>
                                       <div className='rating-percent'>
                                          <span className="rating-no">1 <img src={ImgmStar1} /></span>
                                          <div className='rating-progress'>
                                             <ProgressBar completed={productDetails.data ? productDetails.data.ratings ? ((productDetails.data.ratings.one * 100) / productDetails.data.ratings.total_rating_count) : 0 : 0} />
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 : null : 0 : 0}
                              {/* <div className="rating-right">
                              <div className="rating-percents">
                                 <div className="rating-percent">
                                    <span className="rating-no">5 <img src={ImgmStar} /></span>
                                    <div className="rating-progress"></div>
                                 </div>
                                 <div className="rating-percent">
                                    <span className="rating-no">4 <img src={ImgmStar} /></span>
                                    <div className="rating-progress"></div>

                                 </div>
                                 <div className="rating-percent">
                                    <span className="rating-no">3 <img src={ImgmStar} /></span>
                                    <div className="rating-progress"></div>

                                 </div>
                                 <div className="rating-percent">
                                    <span className="rating-no">2 <img src={ImgmStar} /></span>
                                    <div className="rating-progress"></div>
                                 </div>
                                 <div className="rating-percent">
                                    <span className="rating-no">1 <img src={ImgmStar} /></span>
                                    <div className="rating-progress"></div>
                                 </div>
                              </div>
                           </div> */}
                           </div>
                        </div>
                     </section>
                     : null : null}
                  {productRating.data ? productRating.data.length > 0 ?
                     <section className="space-cls customer-review_sec">
                        <div className="container">
                           <div className="perfume_inner_title">
                              <h4>{strings.customers_reviews} ({productRating ? productRating.data ? productRating.data.length > 0 ? productRating.data.length : 0 : 0 : 0})</h4>
                           </div>
                           <div className="reivew-inner-list">
                              {productRating.data ?
                                 productRating.data.map((item, index) =>
                                    <div className="review-inner">
                                       <div className="reviiew-dtl">
                                          <div className="review-left">
                                             <span className="review-dtl-bg">{item.rating} <span><img src={ImgmStar} /></span></span>
                                          </div>
                                          <div className="review-right">
                                             <p>{item.review}.</p>
                                             <span>{item ? item.users ? item.users.name : '' : ''} | {item ? moment.utc(item.updated_at).format('DD-MMMM-YYYY') : ''}  </span>
                                          </div>
                                       </div>
                                    </div>) : <section className="space-cls no_image review_noImg">
                                    <div className="container">
                                       <div className="cart_row">
                                          <div className='col-md-12'>
                                             <h3>
                                                <center>
                                                   <img src={noReview} alt="Cart Product" />
                                                </center>
                                             </h3>
                                          </div>
                                       </div>
                                    </div>
                                 </section>}

                           </div>
                        </div>
                     </section>
                     : null : null}
               </>
               : <Skeleton height={50} count={20} />}
            <CommanList />
            {productDetails.data ? productDetails.data.related_brand_product ? productDetails.data.related_brand_product.length > 0 ?
               <section className="space-cls home-fragrance-prd-sec">
                  <div className="container">
                     <div className="title-main">
                        <h2 className="heading-type1">You May Also Like</h2>
                        {/* {commanData.data && commanData.data.most_sold != '' ? <h2 className="heading-type1">{strings.mostPickedProduct}</h2> : ''} */}
                     </div>
                     {productDetails.data ? <Product data={productDetails.data.related_brand_product} options={newAriveOption} /> : <Skeleton height={50} count={20} />}

                  </div>
               </section>
               : null : null : null}

         </main>
         <Footer />
      </>
   )
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);