import React, { useState, useEffect, useRef } from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import LeftSitebar from '../layout/LeftSitebar';
import OwlCarousel from 'react-owl-carousel3';
import ImgmA1 from '../assets/img/a1.png';
import ImgmA2 from '../assets/img/a2.png';
import ImgmA3 from '../assets/img/a3.png';
import ImgmA4 from '../assets/img/a4.png';
import ImgmStar from '../assets/img/star.png';
import ImgmFavorite from '../assets/img/favorite.png';
import ImgmOutofstock from '../assets/img/outofstock.png';
import ImgBestSeller from '../assets/img/best-seller.png';
import { Navigate, useParams } from 'react-router-dom';
import ImgLocationIcon from '../assets/img/location_icon.png';
import ImgCountryFlag from '../assets/img/country_flag.png';
import ImgGift1 from '../assets/img/gift-1.png';
import apiPath from "../constants/apiPath";
import Http from "../services/Http";
import toast, { Toaster } from 'react-hot-toast';
import { SelectGroup, TextInput, TextInputGroup, ValidationForm } from 'react-bootstrap4-form-validation';
import { Modal, Form, Button } from 'react-bootstrap';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import LocalizedStrings from 'react-localization';
import localizations from "../localization";
let strings = new LocalizedStrings(localizations);


const options = {
	margin: 0,
	nav: true,
	dots: false,
	loop: true,
	responsive: {
		0: {
			items: 1,
			margin: 7.5
		},
		420: {
			items: 1
		},
		577: {
			items: 1
		},
		992: {
			items: 1
		}
	}
};

const newAriveOption = {
	margin: 15,
	nav: true,
	dots: false,
	loop: true,
	responsive: {
		0: {
			items: 2,
			margin: 7.5
		},
		420: {
			items: 2.5,
			margin: 7.5
		},
		577: {
			items: 3,
			margin: 7.5
		},
		767.98: {
			items: 4
		},
		992: {
			items: 5
		},
		1366: {
			items: 7
		}
	}
}


const Address = (Props) => {
	const params = useParams()
	const [user, setUser] = useState([]);
	const [common, setCommon] = useState({
		immediate: true,
		setFocusOnError: true,
		clearInputOnReset: false,
		image: '',
		is_redirect: false
	});
	const formRef = React.createRef();
	const [country, setCountry] = useState([]);
	const [state, setState] = useState([]);
	const [addressType, setAddressType] = useState();
	const [address, setAddress] = useState([]);
	const [placeAddress, setPlaceAddress] = useState()
	const [getRegister, setRegister] = useState({
		mobile: '',
		country_code: '',
	});
	const autoCompleteRef = useRef();
	const inputRef = useRef();


	const [currentLang, setCurrentLang] = useState(
		localStorage.getItem("current_lang")
			? localStorage.getItem("current_lang")
			: "en"
	);
	strings.setLanguage(currentLang);


	const getCountry = async () => {
		let path = apiPath.getCountry;
		var data = new FormData();
		let options = {
			method: 'POST',
		}
		let result = await Http(path, options);
		if (result.status === 200) {
			if (result.data) {
				await setTimeout(async () => {
					await setCountry({
						data: result.data.data,
					})
				}, 3000);

			} else {
				toast.error(result.data.message);
			}
		} else {
			toast.error(result.statusText);
		}
	}
	const onSubmit = async (e, formData, inputs) => {
		e.preventDefault();
		//if(validate()){ 
		let path = apiPath.addEditAddress + "/" + atob(params.id);
		formData.address_type = addressType ? addressType.address_type : "Home"
		// formData.address = placeAddress ? placeAddress.data ? placeAddress.data.formatted_address : address.address : address.address
		formData.mobile_number = getRegister ? getRegister.mobile : ""
		formData.country_code = getRegister ? getRegister.country_code : ''
		//var data = new formData();
		// data.append('address_type',);

		let options = {
			method: 'POST',
			data: formData
		}
		let result = await Http(path, options);
		if (result.status === 200) {
			if (result.data.status) {
				toast.success(result.data.message);
				setCommon({ is_redirect: true })				// window.location.href = "/profile"
			} else {
				toast.error(result.data.message);
			}
		} else {
			toast.error(result.statusText);
		}
		//   }
	}
	const addressErrorSubmit = (e, formData, errorInputs) => {

	}
	const handleChange = (value, data, event, formattedValue) => {
		var country_code = data.dialCode;
		setRegister({ country_code: country_code, mobile: String(value.slice(data.dialCode.length)) });
	}
	const handleChange1 = async (e) => {
		// console.log(state.data);
		// alert(e.target.name)
		if (e.target.name == "country_id") {
			country.data && country.data.map((value) => {
				// console.log(value,"jjjjjjjjjjj")
				if (value.id == e.target.value) {
					setState({ data: value.state })

				}

				setAddress({ ...address, state: '' })
			})
		}
		else {
			country.data && country.data.map((value) => {
				if (value.id == e.target.value) {
					setState({ data: value.state })
				}
				setAddress({ ...address, state: '' })
			})

		}
		setAddress({ ...address, [e.target.name]: e.target.value })
	}
	const onRadioChange = (e) => {
		setAddressType({ [e.target.name]: e.target.value })
	}
	const getAddress = async (id) => {
		let path = apiPath.getAddress + '/' + id;
		var data = new FormData();
		let options = {
			method: 'GET',
		}
		let result = await Http(path, options);
		if (result.status === 200) {
			if (result.data) {
				// console.log(result.data.data)
				await setAddress(result.data.data)
				await getState(result.data.data.country_id)

				// country.data && country.data.map((value) => {
				// 	if (value.id == result.data.data.country_id) {
				// 		setState({ data: value.state })
				// 	}
				// })
			} else {
				toast.error(result.data.message);
			}
		} else {
			toast.error(result.statusText);
		}
	}
	const getState = async (id) => {
		let path = apiPath.getStates + "/" + id;
		var data = new FormData();
		let options = {
			method: 'GET',
		}
		let result = await Http(path, options);
		if (result.status === 200) {
			if (result.data) {
				// let resultJson = result.data.result;
				// await setTimeout(async () => {
				await setState({
					data: result.data.data,
				})
				// }, 3000);

			} else {
				toast.error(result.data.message);
			}
		} else {
			toast.error(result.statusText);
		}
	}
	useEffect(() => {
		getCountry();
		getAddress(atob(params.id))
	}, []);
	return (
		<>
			{common.is_redirect ? <Navigate to="/profile" replace={true} /> : ''}
			<Header />
			<main>

				<section className="space-cls address-page">
					<div className="container">
						<div className="my-account-inner">
							<LeftSitebar />
							<div className="myaccount_r">
								{/* <div className="myaccount_bg"> */}
								{/* <div className="myaccount_dtl">
										<div className="profile_bg">
											<div className="profile_img">
												<img src={ImgGift1} alt="User" />
											</div>
										</div>
										<div className="user-profile-dtl">
											<h3>John Specter</h3>
											<div className="user-mail">
												<span>Email Address</span>
												<a href="mailto:john_specter@gmail.com">john_specter@gmail.com</a>
											</div>
											<div className="user-contact-dtl">
												<div className="mob-n">
													<span>Mobile Number</span>
													<a href="tel:+965 01234567891">+965 01234567891</a>
												</div>
												<div className="whatsapp-n">
													<span>Whatsapp Number</span>
													<a href="tel:+965 01234567891">+965 01234567891</a>
												</div>
											</div>
											<div className="edit-profile">
												<a href="#" className="btn btn_primary">Edit Profile</a>
											</div>
										</div>
									</div> */}
								{/* </div> */}
								<div className="added-address">
									{/* <h3 className="heading-type2">My Addresses</h3>
									<div className="address_select">
										<div className="address_inner_select">
											<label className="custom_radio_b">
												<input type="radio" checked="checked" name="radio" />
												<span className="checkmark"></span>
												<span className="address_tit">Home</span>
												<span className="address_tag">123, lorem ipsum, dummy text</span>
											</label>
											<a href="#">Edit</a>
										</div>
										<div className="address_inner_select">
											<label className="custom_radio_b">
												<input type="radio" name="radio" />
												<span className="checkmark"></span>
												<span className="address_tit">Office</span>
												<span className="address_tag">123, lorem ipsum, dummy text</span>
											</label>
											<a href="#">Edit</a>
										</div>
								</div> */}



									<ValidationForm onSubmit={onSubmit}
										onErrorSubmit={addressErrorSubmit}
										ref={formRef}
										immediate={common.immediate}
										setFocusOnError={common.setFocusOnError}
										defaultErrorMessage={{ required: "Please enter something." }}
									>
										<h2 className="heading-type2">{strings.edit_address}</h2>

										<div className="">

											<div className="row">
												<div className="col-md-6">
													<Form.Group className="form-group" controlId="form-group">
														<Form.Label>{strings.country}</Form.Label>

														<SelectGroup className="form-control" name="country_id" id="country_id"

															value={address ? address.country_id : ''} onChange={handleChange1}
															required errorMessage={strings.plz_select_country}
														>
															<option value="">{strings.plz_select_country}</option>
															{country.data && country.data.map((option, index) => {
																return <option key={option.id} value={option.id}>
																	{option.name}
																</option>
															})}
														</SelectGroup>
													</Form.Group>
												</div>

												{parseInt(address.state_id) && state.data && state.data.length > 0 ?
													<div className="col-md-6">
														<Form.Group className="form-group" controlId="form-group">
															<Form.Label>{strings.state}</Form.Label>
															<SelectGroup className="form-control" name="state_id" id="state_id"
																onChange={handleChange1}
																value={address ? address.state_id : ''}
																errorMessage={strings.plz_enter_state}
															>
																<option value="">{strings.plz_enter_state}</option>
																{state.data && state.data.map((option, index) => {
																	return <option key={option.id} value={option.id}>
																		{option.name}
																	</option>
																})}
															</SelectGroup>
														</Form.Group>
													</div>

													: state.data ? state.data.length > 0 ? <div className="col-md-6">
														<Form.Group className="form-group" controlId="form-group">
															<Form.Label>{strings.state}</Form.Label>
															<SelectGroup className="form-control" name="state_id" id="state_id"
																onChange={handleChange1}
																value={address ? address.state_id : ''}
																errorMessage={strings.plz_enter_state}
															>
																<option value="">{strings.plz_enter_state}</option>
																{state.data && state.data.map((option, index) => {
																	return <option key={option.id} value={option.id}>
																		{option.name}
																	</option>
																})}
															</SelectGroup>
														</Form.Group>
													</div> : <div className="col-md-6">
														<Form.Group className="form-group" controlId="form-group">
															<Form.Label>{strings.state}</Form.Label>

															<TextInput name="state_id" id="state_id" required
																value={address ? parseInt(address.state_id) ? '' : address.state_id : ''}
																placeholder={strings.plz_enter_state}
																errorMessage={strings.plz_enter_state}
																onChange={handleChange1}
															/>


														</Form.Group>
													</div> : <div className="col-md-6">
														<Form.Group className="form-group" controlId="form-group">
															<Form.Label>{strings.state}</Form.Label>

															<TextInput name="state_id" id="state_id" required
																value={address ? parseInt(address.state_id) ? '' : address.state_id : ''}
																placeholder={strings.plz_enter_state}
																errorMessage={strings.plz_enter_state}
																onChange={handleChange1}
															/>


														</Form.Group>
													</div>}
												<div className="col-md-6">
													<Form.Group className="form-group" controlId="form-group">
														<Form.Label>{strings.city}</Form.Label>
														<TextInput name="city_id" id="city_id" required
															value={address ? address.city_id : ''}
															placeholder={strings.plz_enter_city}
															errorMessage={strings.plz_enter_city}
															onChange={handleChange1}
														/>
													</Form.Group>
												</div>
												<div className="col-md-6">
													<Form.Group className="form-group" controlId="form-group">
														<Form.Label>{strings.Block}</Form.Label>
														<TextInput name="block" id="block" required
															value={address ? address.block : ''}
															placeholder={strings.plz_enter_area}
															errorMessage={strings.plz_enter_area}
															onChange={handleChange1}
														/>
													</Form.Group>


												</div>
												<div className="col-md-6">
													<Form.Group className="form-group" controlId="form-group">
														<Form.Label>{strings.street}</Form.Label>
														<TextInput name="street" id="street" required
															value={address ? address.street : ''}
															placeholder={strings.plz_enter_street}
															errorMessage={strings.plz_enter_street}
															onChange={handleChange1}
														/>
													</Form.Group>


												</div>
												<div className="col-md-6">
													<Form.Group className="form-group" controlId="form-group">
														<Form.Label>{strings.flat_number}</Form.Label>
														<TextInput value={address ? address.flat_floor : ''} name="flat_floor" id="flat_floor" required

															placeholder={strings.plz_enter_flat_number}
															errorMessage={strings.plz_enter_flat_number}
															onChange={handleChange1}
														/>
													</Form.Group>

												</div>
												<div className="col-md-6">
													<Form.Group className="form-group" controlId="form-group">
														<Form.Label>{strings.landmark}</Form.Label>
														<TextInput name="landmark" id="landmark" required
															value={address ? address.landmark : ''}
															placeholder={strings.plz_enter_landmark}
															errorMessage={strings.plz_enter_landmark}
															onChange={handleChange1}
														/>
													</Form.Group>

												</div>

												<div className="col-md-6">
													<Form.Group className="form-group" controlId="form-group">
														<Form.Label>Mobile number</Form.Label>
														<PhoneInput inputProps={{
															name: "mobile",
															required: true,
															autoFocus: false,
														}}
															isValid={(value, country) => {
																if (value.match(/1234/)) {
																	return 'Invalid value: ' + value + ', ' + country.name;
																} else if (value.match(/1234/)) {
																	return 'Invalid value: ' + value + ', ' + country.name;
																} else {
																	return true;
																}
															}}
															country={"kw"} value={address ? ((address.country_code ? address.country_code : "+965") + (address.mobile_number ? address.mobile_number : 0)) : ''} onChange={handleChange} />
													</Form.Group>
												</div>





												<div className="col-md-6">
													<Form.Group className="form-group" controlId="form-group">
														<Form.Label>{strings.note}</Form.Label>
														<TextInput name="notes" id="notes"
															value={address ? address.notes : ''}
															placeholder={strings.plz_enter_note}
															errorMessage={strings.plz_enter_note}
															onChange={handleChange1}
														/>
													</Form.Group>
													{/* <div className="form-group">
															<input type="text" name="" placeholder="Block" className="form-control" />
														</div> */}
												</div>



												<div className="col-md-12">
													<div className="form-group form-submit">
														<Button type="submit" className="btn btn_primary">{strings.submit}</Button>
													</div>
												</div>
											</div>

										</div>
									</ValidationForm>
								</div>
							</div>
						</div>
					</div>
				</section>

			</main>
			<Footer />
		</>
	)
}
export default Address;