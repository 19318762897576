import React,{ useState, useEffect }from 'react';  
import apiPath from "../constants/apiPath";
import Http from "../services/Http";   
import toast, { Toaster } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import Header from '../layout/Header';
import Footer from '../layout/Footer';
const Faq = () =>{

	const [page, setPage] = useState([]);
    const getPage = async () =>{		
    let path = apiPath.staticContent+'/faq';
    var data = new FormData();
    let options = {
        method: 'GET',
        data:data
    }
    let result = await Http(path, options);
    if (result.status === 200) {
        if (result.data.status) {
            setPage(result.data.data)
        } else {
            toast.error(result.data.message);
        }
    } else {
        toast.error(result.statusText);
    }
}

useEffect(()=>{
    getPage();  
},[]);
    return (
        <>
        <Header/>
        <main>
            <section className="space-cls cart-page">
                <div className="container">
                <div className="inner-tit">
                  
                    <div>
                            <h2>{page ? page.name :''}</h2>
                    <br/>
                    <p>
                        <p>
                            <div  dangerouslySetInnerHTML={{ __html: page ? page.description :'' }}></div>
                        </p>
                    </p>
                    </div>
                    </div>
                </div>
            </section>
        </main>


        <Footer/>
        </> 

    )
}
export default Faq;