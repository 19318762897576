import React, { useState, useEffect } from 'react';
import { Link, Navigate } from "react-router-dom";
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import LeftSitebar from '../layout/LeftSitebar';
import Imggift1 from '../assets/img/gift-1.png';


import { userLogin } from "../actions/index";
import { connect } from "react-redux";

import apiPath from "../constants/apiPath";
import Http from "../services/Http";
import toast, { Toaster } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'



import { ValidationForm, TextInput, TextInputGroup, FileInput, SelectGroup, Checkbox } from "react-bootstrap4-form-validation";
import { Modal, Form, Button } from 'react-bootstrap';
import validator from 'validator'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'



import LocalizedStrings from 'react-localization';
import localizations from "../localization";
import OTPverifyUpdate from './Modal/OTPverifyUpdate';
let strings = new LocalizedStrings(localizations);


//import fs from 'fs'


const mapStateToProps = state => {
  return { user: state.user };
};

const mapDispatchToProps = dispatch => {
  return {
    userLogin: user => dispatch(userLogin(user))
  };
}


const EditProfile = (Props) => {
  const [user, setUser] = useState(Object.keys(Props.user).length > 0 ? Props.user.details : null)
  const [common, setCommon] = useState({
    immediate: true,
    setFocusOnError: true,
    clearInputOnReset: false,
    image: '',
    is_redirect: false
  });
  const [file, setFile] = useState();
  const formRef = React.createRef();
  const [showOtp, setShowOtp] = useState(false);
  const [getRegister, setRegister] = useState({
    mobile: '',
    country_code: '',
  });
  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("current_lang")
      ? localStorage.getItem("current_lang")
      : "en"
  );
  strings.setLanguage(currentLang);

  const profileSubmit = async (e, formData, inputs) => {
    e.preventDefault();

    //if(validate()){ 
    let path = apiPath.updateProfile;
    var data = new FormData();
    //var data = new formData();
    if (common.image) {
      data.append('image', common.image);
    }
    data.append('name', user.name);
    data.append('email', formData.email);
    data.append('gender', formData.gender);
    data.append('whatsapp_number', user.whatsapp_number);
    data.append('country_code', user.country_code);
    data.append('mobile', user.mobile);
    let options = {
      method: 'POST',
      data: data
    }
    let result = await Http(path, options);
    if (result.status === 200) {
      if (result.data.status) {
        Props.userLogin({ token: Props.user.token, details: result.data.data });
        setUser(result.data.data);
        toast.success(result.data.message);


      } else {
        toast.error(result.data.message);
      }
    } else {
      toast.error(result.statusText);
    }
    //   }
  }
  const updateMobileNumber = async () => {
    let path = apiPath.changeMobileNumber;
    var data = new FormData();

    data.append('country_code', user.country_code);
    data.append('mobile', user.mobile);
    let options = {
      method: 'POST',
      data: data
    }
    let result = await Http(path, options);
    if (result.status === 200) {
      if (result.data.status) {
        Props.userLogin({ token: Props.user.token, details: result.data.data });
        setUser(result.data.data);
        toast.success(result.data.message);
        setShowOtp(true);
        // setCommon({ ...common, is_redirect: true });

      } else {
        toast.error(result.data.message);
      }
    } else {
      toast.error(result.statusText);
    }
  }

  /*
  const  validate = () => {
    let isValid = true;
    if (getRegister.mobile.length === 0) {
        toast.error('Mobile is required');
        isValid = false;
    }
    if (getRegister.mobile.length > 0) {
        if (getRegister.mobile.match(/^[0-9]{0,7}$/)) {
            toast.error('Required 8 To 13 Digit');
            isValid = false;
        }
    }        
    return isValid;
  }*/


  const profileErrorSubmit = (e, formData, errorInputs) => {

  }


  const handleChange = (value, data, event, formattedValue) => {
    var country_code = data.dialCode;
    setUser({ ...user, country_code: country_code, mobile: value.slice(data.dialCode.length) });
    setRegister({ country_code: country_code, mobile: value.slice(data.dialCode.length) });
  }
  const handleChange2 = (value, data, event, formattedValue) => {
    setUser({ ...user, whatsapp_number: value })
  }
  const handleChange1 = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const handleChangeImg = (e) => {
    setCommon({ ...common, [e.target.name]: e.target.files[0] })
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  return (
    <>
      {showOtp ? <OTPverifyUpdate data={showOtp} closeModal={() => setShowOtp(false)} userData={getRegister} /> : ''}
      {user ? user.temp_user == 1 ? <Navigate to="/" replace={true} /> : '' : <Navigate to="/" replace={true} />}
      {common.is_redirect ? <Navigate to="/profile" replace={true} /> : ''}
      <Header />
      <main>
        <section className="space-cls edit-account-page">
          <div className="container">
            <div className="my-account-inner">
              <LeftSitebar />
              <div className="myaccount_r">
                <ValidationForm onSubmit={profileSubmit}
                  onErrorSubmit={profileErrorSubmit}
                  ref={formRef}
                  immediate={common.immediate}
                  setFocusOnError={common.setFocusOnError}
                  defaultErrorMessage={{ required: "Please enter something." }}
                >

                  <h2 className="heading-type2">{strings.edit_profile}</h2>
                  <div className="myaccount_dtl">
                    <div className="profile-edit-bor">
                      <div className="profile_img">
                        <img src={file ? file : user.image} alt="User" />
                      </div>
                    </div>
                    <div className="edit-profile">
                      <div className="upload_cls">
                        <div className="file_input">
                          <FileInput name="image" id="image"
                            fileType={["jpg", "png", "gif", "webp"]}
                            maxFileSize="5120 kb"
                            errorMessage={
                              {
                                required: strings.plz_upload_file,
                                fileType: "Only jpg,png,gif and webp files is allowed",
                                maxFileSize: strings.max_file_size
                              }
                            }
                            onChange={handleChangeImg}
                          />
                          <span for="image">{strings.edit_profile_pic}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="form-group" controlId="form-group">
                        <Form.Label>{strings.name}</Form.Label>
                        <TextInput name="name" id="name" required
                          value={user.name}
                          placeholder="Enter the Name"
                          errorMessage={strings.plz_enter_name}
                          onChange={handleChange1}
                        />
                      </Form.Group>

                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group" controlId="form-group">
                        <Form.Label>Email</Form.Label>
                        <TextInputGroup name="email" id="email" type="email"
                          placeholder={strings.plz_enter_email}
                          validator={validator.isEmail}
                          errorMessage={{ validator: strings.plz_enter_valid_email }}
                          value={user.email}
                          onChange={handleChange1}
                          required
                        />


                      </Form.Group>

                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group" controlId="form-group">
                        <Form.Label>Mobile number</Form.Label>
                        <PhoneInput inputProps={{
                          name: "mobile",
                          required: true,
                          autoFocus: false,
                        }}
                          isValid={(value, country) => {
                            if (value.match(/1234/)) {
                              return 'Invalid value: ' + value + ', ' + country.name;
                            } else if (value.match(/1234/)) {
                              return 'Invalid value: ' + value + ', ' + country.name;
                            } else {
                              return true;
                            }
                          }}
                          country={"kw"} value={(user.country_code ? user.country_code : "965") + (user.mobile ? user.mobile : 0)} onChange={handleChange} />
                      </Form.Group>
                    </div>
                    {/* <div className="col-md-2">
                      <Form.Label className="verify_btn">Verify</Form.Label>
                      <Button onClick={updateMobileNumber} className="btn_primary">Verify</Button>
                    </div> */}
                    <div className="col-md-6">



                      <Form.Group className="form-group" controlId="form-group">
                        <Form.Label>Whatsapp number</Form.Label>
                        <PhoneInput inputProps={{
                          name: "whatsapp_number",
                          required: true,
                          autoFocus: false,
                        }}
                          isValid={(value, country) => {
                            if (value.match(/1234/)) {
                              return 'Invalid value: ' + value + ', ' + country.name;
                            } else if (value.match(/1234/)) {
                              return 'Invalid value: ' + value + ', ' + country.name;
                            } else {
                              return true;
                            }
                          }}
                          country={"kw"} value={user.whatsapp_number} onChange={handleChange2} />
                      </Form.Group>
                    </div>
                    <div className="col-md-12">
                      <Form.Group className="form-group" controlId="form-group">
                        <Form.Label>Gender</Form.Label>
                        <SelectGroup name="gender" id="gender"
                          value={user.gender}
                          required errorMessage={strings.plz_select_gender}
                          onChange={handleChange1}>
                          <option value="">{strings.plz_select_gender}</option>
                          <option value="male">{strings.male}</option>
                          <option value="female">{strings.female}</option>
                        </SelectGroup>
                      </Form.Group>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group form-submit">
                        <Button type="submit" className="btn btn_primary">{strings.submit}</Button>
                      </div>
                    </div>
                  </div>
                </ValidationForm>
              </div>
            </div>

          </div>
        </section>

      </main>
      <Footer />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);