import React, { useState, useEffect } from 'react';
import { Row, Col, Button, FormGroup } from 'react-bootstrap';
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import validator from 'validator';

import toast, { Toaster } from 'react-hot-toast';
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import apiPath from "../../constants/apiPath";
import Http from "../../services/Http";

import LocalizedStrings from 'react-localization';
import localization from '../../localization';
let strings = new LocalizedStrings(localization);


const ContactForm = () => {

    const [contact, setContact] = useState([]);
    const [comman, setComman] = useState({
        setFocusOnError: true,
        clearInputOnReset: false
    });

    const [currentLang, setCurrentLang] = useState(localStorage.getItem('current_lang') ? localStorage.getItem('current_lang') : 'en');

    strings.setLanguage(currentLang);

    const formRef = React.createRef();

    const handleChange = (e) => {
        setContact({ ...contact, [e.target.name]: e.target.value })
    }

    const handleOnChange = (value, data, event, formattedValue) => {
        var country_code = data.dialCode;
        //setComman({...contact,mobile: value.slice(data.dialCode.length), country_code: country_code })
        setContact({ ...contact, phone: value.slice(data.dialCode.length) })
        setComman({ ...comman, country_code: country_code })

    }


    const handleSignupSubmit = async (e) => {
        e.preventDefault();

        let path = apiPath.contactUs;
        var data = new FormData();
        data.append('first_name', contact.first_name);
        data.append('last_name', contact.last_name);
        data.append('email', contact.email);
        data.append('country_code', comman.country_code);
        data.append('mobile', contact.phone);
        data.append('message', contact.message);
        let options = {
            method: 'POST',
            data: data
        }
        let result = await Http(path, options);
        if (result.status === 200) {
            if (result.data.status) {
                setContact(null);
                toast.success(result.data.message, {
                    iconTheme: {
                        primary: '#b30a59',
                        secondary: '#fff',
                    }
                });
                window.location.reload();
            } else {
                toast.error(result.data.message);
            }
        } else {
            toast.error(result.statusText);
        }


    }






    return (
        <ValidationForm onSubmit={handleSignupSubmit} ref={formRef} immediate={comman.immediate} setFocusOnError={comman.setFocusOnError} defaultErrorMessage={{ required: "Please enter something." }} >
            <Row>
                <Col md="6">
                    <FormGroup className='mb-3'>
                        <TextInput value={contact ? contact.first_name : ''} onChange={handleChange} name="first_name" id="first_name" placeholder={strings.enterTheFirstName} required
                            maxLength={100}
                            pattern={"[A-Za-z ]{3,100}"}
                            errorMessage={{
                                required: strings.enterTheFirstName,
                                pattern: strings.parttern_does_match,
                            }}
                        />

                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup className='mb-3'>
                        <TextInput value={contact ? contact.last_name : ''} onChange={handleChange} name="last_name" id="last_name" placeholder={strings.enterTheLastName} required
                            maxLength={100}
                            pattern={"[A-Za-z ]{3,100}"}
                            errorMessage={{
                                required: strings.enterTheLastName,
                                pattern: strings.parttern_does_match,
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup className='mb-3'>
                        <TextInput name="email" id="email" type="text" required
                           // validator={(value) => validator.isEmail(value) && (!validator.contains(value, '+'))}
                            //errorMessage={{ validator: strings.invalidEmailAddress }}
                            errorMessage={{ required: strings.enterTheEmail, pattern: strings.invalidEmailAddress }}
                            pattern='^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+.[a-zA-Z]+$'   
                            value={contact ? contact.email : ''}
                            onChange={handleChange}
                            placeholder={strings.enter_email}
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup className='mb-3'>
                        <ReactPhoneInput
                            inputExtraProps={{
                                name: "mobile",
                                required: true,
                                autoFocus: false,
                            }}
                            isValid={(value, country) => {
                                if (value.match(/1234/)) {
                                    return 'Invalid value: ' + value + ', ' + country.name;
                                } else if (value.match(/1234/)) {
                                    return 'Invalid value: ' + value + ', ' + country.name;
                                }
                                else {
                                    return true;
                                }
                            }}
                            country={"kw"}
                            value={comman ? comman.country_code : '' + contact ? contact.phone : ''}
                            onChange={handleOnChange}
                        />
                    </FormGroup>
                </Col>
                <Col md="12">
                    <FormGroup className='mb-3'>
                        <TextInput value={contact ? contact.message : ''} onChange={handleChange} name="message" id="message" placeholder='message'
                            required
                            multiline={true}
                            numberOfLines={4}
                            errorMessage={{
                                required: strings.enter_message,
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col md="12">
                    <FormGroup>
                        <Button type="submit" className="btn btn_primary">{strings.submit}</Button>
                    </FormGroup>
                </Col>
            </Row>
        </ValidationForm>
    )
}

export default ContactForm;