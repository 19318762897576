import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel3';
import ImgAppStore from '../assets/img/app-store.png';
import ImgGooglePlay from '../assets/img/google-play.png';
import ImgAppGallery from '../assets/img/app-gallery.png';
import ImgAppIc1 from '../assets/img/app_ic1.png';
import ImgAppIc2 from '../assets/img/app_ic2.png';
import ImgAppIc3 from '../assets/img/app_ic3.png';
import ImgThumbLike from '../assets/img/thumb-like.png';
import ImgFacebook from '../assets/img/facebook.png';
import ImgYoutube from '../assets/img/youtube.png';
import ImgInstagram from '../assets/img/instagram.png';
import ImgWhatsapp from '../assets/img/whatsapp.png';
import ImgGoogle from '../assets/img/google.png';
import ImgTwitter from '../assets/img/twitter.png';
import ImgSnapshot from '../assets/img/snapshot.png';
import ImgTiktok from '../assets/img/tiktok.png';
import ImgPaymentMethod from '../assets/img/payment-method.png';
import NewsLetter from "../view/Component/NewsLetter";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import apiPath from "../constants/apiPath";
import Http from "../services/Http";
import toast, { Toaster } from 'react-hot-toast';

import LocalizedStrings from 'react-localization';
import localizations from "../localization";
let strings = new LocalizedStrings(localizations);

const customStyleTawk = {
  visibility : {
    mobile : {
        xOffset : 15,
        yOffset : 80,
        position : 'br'
    }
  }
};

const options = {
  margin: 0,
  nav: false,
  dots: true,
  loop: true,
  autoplay: true,
  autoplayTimeout: 5000,
  autoplayHoverPause: true,
  responsive: {
    0: {
      items: 1,
      margin: 7.5
    },
    420: {
      items: 1
    },
    577: {
      items: 1
    },
    992: {
      items: 1
    }
  }
};

const Footer = (Props) => {

  //  console.log(Props, "Props")
  const [currentLang, setCurrentLang] = useState(localStorage.getItem('current_lang') ? localStorage.getItem('current_lang') : 'en');

  strings.setLanguage(currentLang);
  const [setting, setSetting] = useState([]);

  const getSetting = async () => {
    let path = apiPath.setting;
    var data = new FormData();
    let options = {
      method: 'GET',
    }
    let result = await Http(path, options);
    if (result.status == 200) {
      if (result.data.status == true) {
        await setSetting({ data: result.data.data })
      }
      else {
        toast.error(result.data.message);
      }
    }
    else {
      toast.error(result.statusText);
    }
  }

  useEffect(() => {
    getSetting();
  }, []);

  return (
    <footer>
      <div className="top-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="download-wrap">
                <h3 className="heading-type2">{strings.downloadOurApp}</h3>
                <div className="download-store">
                  <a href="https://apps.apple.com/us/app/the-perfume-smell/id6446624938" target={'_blank'}>
                    {/* <Link to='https://www.apple.com/in/search/perfume?src=serp' target={'_blank'}> */}
                    <img src={ImgAppStore} alt="App Store" />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.app.the_perfume_smell" target={'_blank'}>
                    {/* <Link to="https://play.google.com/store/search?q=perfume&c=apps" target={'_blank'}> */}
                    <img src={ImgGooglePlay} alt="App Store" />
                  </a>
                  <a href="https://appgallery.huawei.com/search/perfume" target={'_blank'}>
                    {/* <Link to="https://appgallery.huawei.com/search/perfume" target={'_blank'}> */}
                    <img src={ImgAppGallery} alt="App Store" />
                  </a>
                </div>
                <div className="app-dtl-wrap">
                  <div className="app-list">
                    <div className="app-col">
                      <div className="app-box">
                        <div className="app-icon">
                          <img src={ImgAppIc1} alt={strings.genuineProducts} />
                        </div>
                        <div className="app_title">
                          <h3>{strings.genuineProducts}</h3>
                        </div>
                      </div>
                    </div>
                    <div className="app-col">
                      <div className="app-box">
                        <div className="app-icon">
                          <img src={ImgAppIc2} alt={strings.secureShopping} />
                        </div>
                        <div className="app_title">
                          <h3>{strings.secureShopping}</h3>
                        </div>
                      </div>
                    </div>
                    <div className="app-col">
                      <div className="app-box">
                        <div className="app-icon">
                          <img src={ImgAppIc3} alt={strings.EasytoReturn} />
                        </div>
                        <div className="app_title">
                          <h3>{strings.EasytoReturn}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="rating_r_space">
                <div className="rating_s_in">
                  <div className="rating_thumb"><img src={ImgThumbLike} alt="Thumb" /></div>
                  <div className="rating_con">
                    <h4>5.0/5.0</h4>
                    <p>{strings.by} 800+ {strings.customers}</p>
                  </div>
                </div>
                <div className="rating_c_main">
                  <OwlCarousel className="owl-carousel  owl-theme" {...options}  >
                    <div className="item">
                      <div className="rating_cont">
                        <p>{strings.thanksForThis}</p>
                        <p className="rate-user-name">{strings.perterJohn}</p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="rating_cont">
                        <p>{strings.thanksForThis}</p>
                        <p className="rate-user-name">{strings.perterJohn}</p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="rating_cont">
                        <p>{strings.thanksForThis}</p>
                        <p className="rate-user-name">{strings.perterJohn}</p>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="middle-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="foote-cont">
                <div className="footer-title">
                  <h3>{strings.thePerfumeSmall}</h3>
                </div>
                <div className="footer-link">
                  <ul>
                    <li>
                      <Link to="/about-us">{strings.whoAreWe}</Link>
                    </li>
                    <li>
                      <Link to="/faq">{strings.faqs}</Link >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="foote-cont">
                <div className="footer-title">
                  <h3>{strings.help}</h3>
                </div>
                <div className="footer-link">
                  <ul>
                    <li>
                      <Link to="/payment-mode">{strings.paymentMode}</Link >
                    </li>
                    <li>
                      <Link to="/cancel-return">{strings.ReturnRefund}</Link >
                    </li>
                    <li>
                      <Link to="/terms-and-condition">{strings.termsCondations}</Link >
                    </li>
                    <li>
                      <Link to="/privacy-policy">{strings.privacyPolicy}</Link >
                    </li>

                    <li>
                      <Link to="/shipping-deliver">{strings.shippingDelivery}</Link >
                    </li>
                    <li>
                      <Link to="/news-ticker">{strings.NewsTicker}</Link >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="foote-cont">
                <div className="footer-title">
                  <h3>{strings.quickLink}</h3>
                </div>
                <div className="footer-link">
                  <ul>
                    <li>
                      <Link to="/offer">{strings.offers}</Link >
                    </li>
                    <li>
                      <Link to="/contact-us">{strings.contactUs}</Link >
                    </li>
                    <li>
                      <Link to="/tracking-order">{strings.track_your_order}</Link >
                    </li>
                    {/*<li>
                        <Link to="/gift-card">Gift Cards</Link>
                      </li>*/}

                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="foote-cont">
                <div className="footer-title">
                  <h3>{strings.top_categories}</h3>
                </div>
                <div className="footer-link">
                  <ul>
                    <li>
                      <Link to="/product/men">{strings.man}</Link>
                    </li>
                    <li>
                      <Link to="/product/women">{strings.woman}</Link>
                    </li>
                    <li>
                      <Link to="/product/unisex">{strings.unisex}</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="subscribe-sec">
            <div className="row">
              <div className="col-md-8">
                <NewsLetter />
              </div>
              <div className="col-md-4">
                <div className="footer-social-sec">
                  <ul>
                    {setting.data ? setting.data.facebook_url != null ?
                      <li>
                        <a href={setting.data.facebook_url} target={'_blank'}>
                          <img src={ImgFacebook} alt="Facebook" />
                        </a>
                      </li>
                      : '' : ''}
                    {setting.data ? setting.data.instagram_url != null ?
                      <li>
                        <a href={setting.data.instagram_url} target={'_blank'}>
                          <img src={ImgInstagram} alt="Instagram" />
                        </a>
                      </li>
                      : '' : ''}
                    {setting.data ? setting.data.whatsapp_url ?
                      <li>
                        <a href={setting.data.whatsapp_url} target={'_blank'}>
                          <img src={ImgWhatsapp} alt="whatsapp" />
                        </a>
                      </li>
                      : '' : ''}
                    {setting.data ? setting.data.youtube_url ?
                      <li>
                        <a href={setting.data.youtube_url} target={'_blank'}>
                          <img src={ImgYoutube} alt="youtube" />
                        </a>
                      </li>
                      : '' : ''}
                    {setting.data ? setting.data.twitter_url ?
                      <li>
                        <a href={setting.data.twitter_url} target={'_blank'}>
                          <img src={ImgTwitter} alt="Instagram" />
                        </a>
                      </li>
                      : '' : ''}
                    {setting.data ? setting.data.tiktok_url ?
                      <li>

                        <a href={setting.data.tiktok_url} target={'_blank'}>
                          <img src={ImgTiktok} alt="Instagram" />
                        </a>
                      </li>
                      : '' : ''}
                    {setting.data ? setting.data.google_url ?
                      <li>
                        <a href={setting.data.google_url} target={'_blank'}>
                          <img src={ImgGoogle} alt="Instagram" />
                        </a>
                      </li>
                      : '' : ''}
                    {setting.data ? setting.data.snapchat_url ?
                      <li>
                        <a href={setting.data.snapchat_url} target={'_blank'}>
                          <img src={ImgSnapshot} alt="Instagram" />
                        </a>
                      </li>
                      : '' : ''}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="copyright-cont">
                <h4>{setting.data ? setting.data.copyright_content : "The Perfume Smell © 2023. All rights reserved"}</h4>
              </div>
            </div>
            <div className="col-md-6">
              <div className="payment-img">
                <Link href="#">
                  <img src={ImgPaymentMethod} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="App">
        <TawkMessengerReact
          customStyle={ customStyleTawk }
          propertyId="5faa68098e1c140c2abc9221"
          widgetId="default" />
      </div>
    </footer >
  )
}
export default Footer;
