import React, { useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel3';
import { Link } from "react-router-dom";
import apiPath from "../../constants/apiPath";
import Http from "../../services/Http";
import toast, { Toaster } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


import ImgCk from '../../assets/img/ck.png';
import ImgAx from '../../assets/img/ax.png';
import ImgPuma from '../../assets/img/puma.png';
import ImgmA1 from '../../assets/img/a1.png';
import ImgmA2 from '../../assets/img/a2.png';
import ImgmA3 from '../../assets/img/a3.png';
import ImgmA4 from '../../assets/img/a4.png';
import ImgmStar from '../../assets/img/star.png';
import ImgmFavorite from '../../assets/img/favorite.png';
import ImgmOutofstock from '../../assets/img/outofstock.png';
import ImgBestSeller from '../../assets/img/best-seller.png';
import ImgSearch from '../../assets/img/search.png';
import Banner from '../../view/Component/Banner';
import Product from '../../view/Component/Product';
import Currency from './Currency';


import LocalizedStrings from 'react-localization';
import localization from '../../localization';

let strings = new LocalizedStrings(localization);

const CommanList = (Props) => {

	const [currentLang, setCurrentLang] = useState(localStorage.getItem('current_lang') ? localStorage.getItem('current_lang') : 'en');


	const options = {
		margin: 0,
		nav: true,
		dots: false,
		loop: true,
		responsive: {
			0: {
				items: 1,
				margin: 7.5
			},
			420: {
				items: 1
			},
			577: {
				items: 1
			},
			992: {
				items: 1
			}
		}
	};

	const newAriveOption = {
		margin: 15,
		nav: true,
		dots: false,
		loop: true,
		responsive: {
			0: {
				items: 2,
				margin: 7.5
			},
			420: {
				items: 2,
				margin: 7.5
			},
			577: {
				items: 3,
				margin: 7.5
			},
			767.98: {
				items: 4
			},
			992: {
				items: 5
			},
			1366: {
				items: 6
			},
			1480: {
				items: 7
			}
		}
	}


	const [commanData, setCommanData] = useState([]);

	const getComman = async () => {
		console.log("hgjk")
		let path = apiPath.CommonApi;
		var data = new FormData();
		let options = {
			method: 'GET',
		}
		let result = await Http(path, options);
		if (result.status == 200) {
			setCommanData({ data: result.data.data })
		}

	}

	strings.setLanguage(currentLang);

	useEffect(() => {
		getComman();
	}, []);




	return (
		<>

			<section className="space-cls best-seller-sec">
				<div className="container">

					{commanData.data ? <Banner data={commanData.data.sale_banner} options={options} type="sale" /> : <Skeleton height={487} count={1} />}

				</div>
			</section>


			<section className="space-cls home-fragrance-prd-sec">
				<div className="container">

					<div className="title-main">
						{commanData.data && commanData.data.related_product != '' ? <h2 className="heading-type1">{strings.releatedProduct}</h2> : ''}
					</div>
					{commanData.data ? <Product data={commanData.data.related_product} options={newAriveOption} /> : <Skeleton height={100} count={1} />}



				</div>
			</section>
			<section className="space-cls home-fragrance-prd-sec">
				<div className="container">
					<div className="title-main">
						{commanData.data && commanData.data.most_sold != '' ? <h2 className="heading-type1">{strings.mostPickedProduct}</h2> : ''}
					</div>
					{commanData.data ? <Product data={commanData.data.most_sold} options={newAriveOption} /> : <Skeleton height={100} count={1} />}

				</div>
			</section>




		</>

	)
}
export default CommanList;