import { Outlet, Link } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

const Layout = () => {
  return (
    <>
      <ScrollToTop />
      <Outlet />
    </>
  )
}
export default Layout;