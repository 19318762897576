import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Imgcross from '../../assets/img/cross.png';
import { Link } from "react-router-dom";
//import Login from '../Modal/Login';
import Register from '../Modal/Register';

import LocalizedStrings from 'react-localization';
import localization from '../../localization';
import GoogleLogin from './GoogleLogin';
import GoogleLoginNew from './GoogleLoginNew';
import Login from './Login';
let strings = new LocalizedStrings(localization);


const ShowSignBox = (Props) => {

    const [show, setShow] = useState(false);
    const [showLogin, setShowLogin] = useState(false);

    const [currentLang, setCurrentLang] = useState(
        localStorage.getItem("current_lang")
            ? localStorage.getItem("current_lang")
            : "en"
    );
    strings.setLanguage(currentLang);

    const handleClose = () => setShow(false);
    const openLogin = () => {
        // alert("hello")
        setShowLogin(true);
        setShow(false);
    }

    useEffect(() => {
        setShowLogin(false);
        setShow(Props.data);
    }, [Props]);

    return (
        <>
            {/* {showLogin ? <Register data={showLogin} /> : ''} */}
            {showLogin ? <Login data={showLogin} /> : ''}


            <Modal show={show} onHide={handleClose} animation={true} size="lg" backdrop="static" keyboard={false} centered >
                <Modal.Header >
                    <h5 className="modal-title" id="exampleModalLabel">{strings.sign_in}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}>
                        <img src={Imgcross} onClick={handleClose} />
                    </button>
                </Modal.Header>
                <Modal.Body className='modal-body'>

                    <div className="popup-cont">
                        <p className="signin-cont">{strings.special_offer}</p>
                        <div className="continue-btn">
                            <Link to="#" className="btn btn_primary mb-3" onClick={openLogin}>{strings.continue_with_phone}</Link>
                            <div className="App">
                                <GoogleLogin />
                            </div>

                            {/* <GoogleLoginNew /> */}
                            {/* <Link to="#" className="btn btn_primary">{strings.continue_with_google}</Link> */}
                        </div>
                    </div>
                </Modal.Body>

            </Modal>

        </>



    )
}
export default ShowSignBox