import React, { useEffect } from "react";
import ReactGoogleLogin from "react-google-login";
import { gapi } from "gapi-script";
import toast, { Toaster } from 'react-hot-toast';
import apiPath from "../../constants/apiPath";
import Http from "../../services/Http";
import { internalIpV4 } from '../../services/getIp';
import { userLogin } from "../../actions/index";
import { connect } from "react-redux";
const mapStateToProps = state => {
    return { user: state.user };
};
const mapDispatchToProps = dispatch => {
    return {
        userLogin: user => dispatch(userLogin(user))
    };
};

const GoogleLogin = (Props) => {
    const onResponse = async (res, err) => {
        if (res) {
            let path = apiPath.sociallogin;
            var data = new FormData();
            data.append('email', res.profileObj ? res.profileObj.email : '');
            data.append('mobile', res.profileObj ? res.profileObj.email : '');
            data.append('social_id', res.profileObj ? res.profileObj.googleId.toString() : '');
            data.append('social_type', 'Google');
            data.append('device_token', await internalIpV4());
            data.append('device_type', 'Web');
            data.append('name', res.profileObj ? res.profileObj.name : '');
            data.append('preferred_country', Props.user.details.preferred_country);
            data.append('preferred_language', Props.user.details.preferred_language);
            let options = {
                method: 'POST',
                data: data
            }
            let result = await Http(path, options);
            if (result.status === 200) {
                if (result.data.status) {
                    Props.userLogin(result.data.data);
                    window.location.reload();
                }

                else {
                    toast.error(result.data.message);
                }
            } else {
                toast.error(result.statusText);
            }


        }
        else {
            toast.error("Social login failed");
        }
        // console.log(res.profileObj, "resp");
        // console.log(err, "resp");
    };
    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: "366059205737-iqb7t25bhu4d5s7sc80mh8l3legskdu9.apps.googleusercontent.com",
                scope: 'email',
            });
        }

        gapi.load('client:auth2', start);
    }, [Props]);
    return (
        <ReactGoogleLogin
            clientId="366059205737-iqb7t25bhu4d5s7sc80mh8l3legskdu9.apps.googleusercontent.com" // We created this earlier, remember?
            buttonText="Login with Google"
            onSuccess={onResponse}
            onFailure={onResponse}
            cookiePolicy={'_host_origin'}
        />
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(GoogleLogin);