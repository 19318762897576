import OwlCarousel from 'react-owl-carousel3';
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import ImgmA1 from '../../assets/img/a1.png';
import ImgmA2 from '../../assets/img/a2.png';
import ImgmA3 from '../../assets/img/a3.png';
import ImgmA4 from '../../assets/img/a4.png';
import ImgmStar from '../../assets/img/star.png';
import ImgmFavorite from '../../assets/img/favorite.png';
import ImgmOutofstock from '../../assets/img/outofstock.png';
import ImgTrendingPrd from '../../assets/img/trending-prd.png';
import ImgmUnFavorite from '../../assets/img/un-fav.png';
import apiPath from "../../constants/apiPath";
import Http from "../../services/Http";
import toast, { Toaster } from 'react-hot-toast';
import Currency from './Currency';

import LocalizedStrings from 'react-localization';
import localizations from "../../localization";
let strings = new LocalizedStrings(localizations);

const ProductList = (Props) => {

   const [comman, setComman] = useState({
      setfavorite: ImgmFavorite,
      setUnFavorite: ImgmUnFavorite,
      type: 'add'
   });

   const [currentLang, setCurrentLang] = useState(
      localStorage.getItem("current_lang")
         ? localStorage.getItem("current_lang")
         : "en"
   );
   strings.setLanguage(currentLang);



   const [product, setProduct] = useState([]);
   useEffect(() => {
      setProduct(Props.data);
      console.log(product, "priduct")
   }, [Props.data]);

   return (
      <>
         {product.map((item, index) =>
            <div className="col_2 col-4 col-sm-4 col-md-6 col-lg-4 col-xl-3 col_5">

               <div className="arrival-prd">
                  {item.trending_product == 1 ?
                     <div class="trending_badge">
                        <img src={ImgTrendingPrd} alt="" />
                     </div> : ''}

                  {item.out_of_stock == 1 ?
                     <div class="out-of-stock">
                        <img src={ImgmOutofstock} alt={strings.out_of_stock} />
                     </div> : ''}

                  {item.sold_out == 1 ?
                     <div class="out-of-stock">
                        <img src={ImgmOutofstock} alt={strings.out_of_stock} />
                     </div> : ''}


                  {item.discontinue == 1 ?
                     <div class="out-of-stock">
                        <img src={ImgmOutofstock} alt={strings.out_of_stock} />
                     </div> : ''}




                  <div className="arrival-prd-img">
                     <Link to={Props.sale ? '/product-detail/' + item.id + '/sale' : '/product-detail/' + item.id} target="_blank">
                        <img src={item.image} alt="" />
                     </Link>
                  </div>
                  <div className="arrival-cont">

                     <div class="sold_rate">
                        {item.rating > 0 ? <>
                           <div class="ratting-wrap">
                              <span className="ratting-icon">
                                 <img src={ImgmStar} alt="Star" />
                              </span>
                              <span className="rate-dtl">{item.rating}</span>
                           </div> </> : null}
                        <div class="sold_dtl">
                           <p>{item.sold > 0 ? item.sold + " " + strings.sold + " & " + item.left + " " + strings.left : item.left + " " + strings.left}</p>
                        </div>
                     </div>
                     <div className="arrival-title">
                        <h3>{item.name}</h3>
                        <p>{item.brands ? item.brands.name : ''}</p>
                     </div>

                     <div className="arrival-price">


                        <p>{Props.sale ? <Currency data={item.sale.discount_price != 0 ? item.sale.discount_price : item.sale.original_price} /> : <Currency data={item.discount_price != 0 ? item.discount_price : item.original_price} />}

                        </p>
                        <p>{Props.sale ? parseInt(item.sale.discount_price) == 0 ? '' : <p className="discount-price"><span><Currency data={item.sale.original_price} /></span> ({Math.round((((item.sale.original_price - item.discount_price) * 100) / item.sale.original_price).toFixed(2))}% {strings.off})</p> : parseInt(item.discount_price) == 0 ? '' : <p className="discount-price"><span><Currency data={item.original_price} /> </span>({Math.round((((item.original_price - item.discount_price) * 100) / item.original_price).toFixed(2))}% {strings.off})</p>}</p>

                     </div>
                  </div>
                  <div className="prd-add-option">
                     <div className="fav_wrap">
                        {item.is_wishlist == 1 ? <Link className="btn_primary"
                           onClick={(e) => Props.handleFavourite(e, item.id, 'remove', index)}>
                           <img src={comman.setUnFavorite} alt="" />
                        </Link> :
                           <Link className="btn_primary"
                              onClick={(e) => Props.handleFavourite(e, item.id, 'add', index)}>
                              <img src={comman.setfavorite} alt="" />
                           </Link>}
                     </div>
                     <div className="cart_wrap">
                        <Link to={Props.sale ? '/product-detail/' + item.id + '/sale' : '/product-detail/' + item.id} className={(item.out_of_stock == 1) || (item.left <= 0) ? "btn_primary  notify_me_new" : "btn_primary"} target="_blank">{(item.out_of_stock == 1) || (item.left <= 0) ? strings.notifyMe : strings.add_to_bag}</Link>
                     </div>
                  </div>
               </div>
            </div>
         )}
      </>

   )
}
export default ProductList;