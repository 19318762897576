import apiPath from '../constants/apiPath';
//const axios = require('axios')
import axios from 'axios';  
async function Http(path, options) {  
  try{
    let BaseURL = apiPath.baseURL +path;
//    console.log(BaseURL);
    let token = localStorage.getItem("user")? JSON.parse(localStorage.getItem("user")).user.token: '';
    let lang = localStorage.getItem("current_lang") ? localStorage.getItem("current_lang") : "en";
    if(token){
      options.headers = {
        Authorization: `Bearer ${token}`,
        "Accept-Language": lang,
      } 
         
    }

    //console.log(options);

   /* let result = await fetch(BaseURL,options)
    .then(res => res.json())
    .then(
      (result) => {
        console.log(result);
      //  return result;
        
      }).catch(err => {
          console.log(err);
      });*/


        options.url = BaseURL
        let result = await axios(options);

  

    // if(result.status === 401) {
    //   localStorage.removeItem("user");
    //   window.location.reload();
    // }else if(result.status === 409){
    //   localStorage.removeItem("user");
    //   window.location.reload();
    // }
  return result;
  }catch(error){
      if(error.code === "ERR_BAD_REQUEST"){
        let errorss = error.response.status
        if(errorss === 401) {
          localStorage.removeItem("user");
          window.location.href = "/";
        }else if(errorss === 409){
          localStorage.removeItem("user");
          window.location.href = "/";
        }else if(errorss === 404){
          let response = {};
          response.statusText = error.message
          response.status = 404;
          return response;      
        }else{
          localStorage.removeItem("user");
          window.location.href = "/";
        }
      }else{
        let response = {};
        response.statusText = error.message
        response.status = 504;
        return response;
      }
  }
}
export default Http;
